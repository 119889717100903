import { Container } from '@chakra-ui/react'
import { FC, PropsWithChildren } from 'react'
import { Footer } from './footer'
import { Header } from './header'
export const Skeleton: FC<PropsWithChildren> = ({ children }) => (
  <>
    <Header />
    <Container maxW={'container.xl'}>{children}</Container>
    <Footer />
  </>
)
