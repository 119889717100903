import { useMutation, useQuery } from '@tanstack/react-query'
import {
  createApplication,
  createBirthAndAdoption,
  createBirthAndAdoptionLumpSumGrant,
  createCare,
  createEmployment,
  createIncome,
  createSickLeave,
  deleteApplication,
  deleteEmployment,
  deleteIncome,
  deleteSickLeave,
  deleteSickLeavePeriod,
  getApplication,
  getApplicationCalculations,
  getApplicationMessages,
  markMessagesAsRead,
  sendInApplication,
  sendMessage,
  updateApplication,
  updateApplicationApplicant,
  updateBirthAndAdoption,
  updateBirthAndAdoptionLumpSumGrant,
  updateCare,
  updateEmployment,
  updateIncome,
  updateSickLeave,
} from './application.api'
import { useQueryClient } from '@tanstack/react-query'
import { APPLICANT_KEYS } from '.'

export const useGetApplication = (applicationId: string) => {
  return useQuery({
    queryKey: KEYS.APPLICATION(applicationId),
    queryFn: () => getApplication(applicationId),
    enabled: false,
    retry: false,
    staleTime: 1000,
    refetchOnMount: true,
  })
}

export const useGetApplicationCalculations = (applicationId: string) => {
  return useQuery({
    queryKey: KEYS.APPLICATION_CALCULATIONS(applicationId),
    queryFn: () => getApplicationCalculations(applicationId),
    enabled: !!applicationId,
    retry: false,
    staleTime: 1000,
    refetchOnMount: true,
  })
}

export const useCreateApplication = () => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: createApplication,
    onSettled: async () => {
      await queryClient.invalidateQueries({ queryKey: APPLICANT_KEYS.APPLICANT_APPLICATIONS(), exact: false })
    },
  })
}

export const useDeleteApplication = () => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: deleteApplication,
    onSettled: async () => {
      await queryClient.invalidateQueries({ queryKey: APPLICANT_KEYS.APPLICANT_APPLICATIONS(), exact: false })
    },
  })
}

export const useUpdateApplication = () => {
  return useMutation({
    mutationFn: updateApplication,
  })
}

export const useUpdateApplicationApplicant = () => {
  return useMutation({
    mutationFn: updateApplicationApplicant,
  })
}

export const useCreateEmployment = () => {
  return useMutation({
    mutationFn: createEmployment,
  })
}

export const useUpdateEmployment = () => {
  return useMutation({
    mutationFn: updateEmployment,
  })
}

export const useDeleteEmployment = () => {
  return useMutation({
    mutationFn: deleteEmployment,
  })
}

export const useCreateIncome = () => {
  return useMutation({
    mutationFn: createIncome,
  })
}

export const useUpdateIncome = () => {
  return useMutation({
    mutationFn: updateIncome,
  })
}

export const useDeleteIncome = () => {
  return useMutation({
    mutationFn: deleteIncome,
  })
}

export const useCreateSickLeave = () => {
  return useMutation({
    mutationFn: createSickLeave,
  })
}

export const useUpdateSickLeave = () => {
  return useMutation({
    mutationFn: updateSickLeave,
  })
}

export const useCreateCare = () => {
  return useMutation({
    mutationFn: createCare,
  })
}

export const useUpdateCare = () => {
  return useMutation({
    mutationFn: updateCare,
  })
}

export const useCreateBirthAndAdoption = () => {
  return useMutation({
    mutationFn: createBirthAndAdoption,
  })
}

export const useUpdateBirthAndAdoption = () => {
  return useMutation({
    mutationFn: updateBirthAndAdoption,
  })
}

export const useCreateBirthAndAdoptionLumpSumGrant = () => {
  return useMutation({
    mutationFn: createBirthAndAdoptionLumpSumGrant,
  })
}

export const useUpdateBirthAndAdoptionLumpSumGrant = () => {
  return useMutation({
    mutationFn: updateBirthAndAdoptionLumpSumGrant,
  })
}

export const useDeleteSickLeave = () => {
  return useMutation({
    mutationFn: deleteSickLeave,
  })
}

export const useDeleteSickLeavePeriod = () => {
  return useMutation({
    mutationFn: deleteSickLeavePeriod,
  })
}

export const useSendInApplication = () => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: sendInApplication,
    onSuccess: async (_, id) => {
      await queryClient.invalidateQueries({ queryKey: KEYS.APPLICATION(id), exact: false })
      await queryClient.invalidateQueries({ queryKey: APPLICANT_KEYS.APPLICANT_APPLICATIONS(), exact: false })
    },
  })
}

export const useGetApplicationMessages = (applicationId: string) => {
  return useQuery({
    queryKey: ['applicationMessages', applicationId],
    queryFn: () => getApplicationMessages(applicationId),
    enabled: !!applicationId,
    refetchOnWindowFocus: true,
    refetchOnMount: true,
  })
}

export const useMarkMessageAsRead = () => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: markMessagesAsRead,
    onSettled: async () => {
      await queryClient.invalidateQueries({ queryKey: ['applicationMessages'], exact: false })
      await queryClient.invalidateQueries({ queryKey: APPLICANT_KEYS.APPLICANT_APPLICATIONS(), exact: false })
    },
  })
}

export const usePostMessage = () => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: sendMessage,
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: ['applicationMessages'], exact: false })
    },
  })
}

const KEYS = {
  APPLICATION: (id?: string) => ['applications', id],
  APPLICATION_CALCULATIONS: (id?: string) => ['applications', id, 'calculations'],
  APPLICATIONS: 'applications',
}
