import { FC } from 'react'
import { Text, TextProps, Heading } from '@chakra-ui/react'

export const MultilineText: FC<{
  text: string
  textProps: TextProps
}> = ({ text, textProps }) => {
  if (!text) return ''
  return text.split('\n').map((line, index) => {
    const isHeading = line.startsWith('__') && line.endsWith('__')

    if (isHeading) {
      return (
        <Heading key={index} variant={'widgetSmall'}>
          {line.slice(2, -2)}
        </Heading>
      )
    }

    return (
      <Text {...textProps} key={index}>
        {line}
      </Text>
    )
  })
}
