import { z } from 'zod'
import { t } from 'i18next'
// import { deepConvertNullsToUndefined } from './object'

/**
 * Transform data to match a Zod schema.
 * All null values will be converted to undefined.
 * Keys that are not in the schema will be removed.
 * The schema is made partial so if a key that should be there is missing, it will be ignored(not throw error).
 */

// export const transform = <TData extends object, TSchema extends ZodType<TData>>(schema: TSchema, data: TData) => {
//   if (typeof data === 'object') {
//     // @ts-expect-error - zod types are not correct
//     data = deepConvertNullsToUndefined(data)
//   }
//
//   // eslint-disable-next-line @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unsafe-member-access
//   return schema.deepPartial().parse(data) as z.infer<typeof schema>
// }

export const OptionalMoneyStringToNumber = z.preprocess(
  (v) => {
    if (typeof v === 'number') return v
    if (typeof v === 'string') {
      return +v.replace(/\D/g, '')
    }
    return undefined
  },
  z.number().optional(),
  {
    required_error: t('validation.field_required'),
    invalid_type_error: t('validation.field_required'),
  }
)

export const MoneyStringToNumber = z.preprocess(
  (v) => {
    if (typeof v === 'number') return v
    if (typeof v === 'string') {
      return +v.replace(/\D/g, '')
    }
    return undefined
  },
  z
    .number({
      required_error: t('validation.field_required'),
      invalid_type_error: t('validation.field_required'),
    })
    .min(1, {
      message: t('validation.positive_number'),
    }),
  {
    required_error: t('validation.field_required'),
    invalid_type_error: t('validation.field_required'),
  }
)
