import { Container, Grid } from '@chakra-ui/react'
import React from 'react'

// Account selection logic is app dependent. Adjust as needed for different use cases.
export const CenterCenter = ({ children }: { children: React.ReactNode }) => (
  <Container>
    <Grid h={'100dvh'} justifyContent={'center'} alignItems={'center'}>
      {children}
    </Grid>
  </Container>
)
