import { tableAnatomy } from '@chakra-ui/anatomy'
import { createMultiStyleConfigHelpers } from '@chakra-ui/react'

const helper = createMultiStyleConfigHelpers(tableAnatomy.keys)

const baseStyle = helper.definePartsStyle({})

export const tableTheme = helper.defineMultiStyleConfig({
  baseStyle,
})
