export const colors = {
  facebook: {
    '50': '#f5f8fb',
    '100': '#d6e2ed',
    '200': '#b2c8de',
    '300': '#87a9cc',
    '400': '#00468d',
    '500': '#00468d',
    '600': '#003264',
    '700': '#115599',
    '900': '#003366',
  },
  brand: {
    blue: 'hsla(210, 100%, 29%, 1)',
    lightGray: '#EDEFF1',
    black: '#2C2C2C',
    warning: '#FFA200',
    green: {
      '50': '#E8FCF3',
      '100': '#C0F7DC',
      '200': '#97F2C6',
      '300': '#6EEDB0',
      '400': '#45E79A',
      '500': '#1DE283',
      '600': '#17B569',
      '700': '#11884F',
      '800': '#0B5B35',
      '900': '#062D1A',
    },
  },
}
