'use client'
import { doLogout } from '@/lib/utility/logout'
import { Button } from '@chakra-ui/react'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'

export const LogOutButton: FC = () => {
  const { t } = useTranslation()

  return (
    <Button onClick={doLogout} float={'right'} variant={'none'} colorScheme="facebook" fontWeight={500}>
      {t('common.logOut')}
    </Button>
  )
}
