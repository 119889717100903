import { Box, BoxProps } from '@chakra-ui/react'
import { ComponentProps, FC, PropsWithChildren } from 'react'

export const Widget: FC<PropsWithChildren<BoxProps & { variant?: 'gray' | 'white' }>> = ({
  children,
  variant = 'gray',
  ...rest
}) => {
  return (
    <Box
      p={{
        base: 4,
        lg: 8,
      }}
      bgColor={variant === 'gray' ? 'brand.lightGray' : 'white'}
      borderRadius={'sm'}
      {...rest}
    >
      {children}
    </Box>
  )
}
export type WidgetProps = ComponentProps<typeof Widget>
