import { Button, VStack, Text, Box, Center } from '@chakra-ui/react'
import { FC, ReactNode } from 'react'
import { useTranslation } from 'react-i18next'
import { BaseModal } from '@/components/modals/BaseModal'
import { CaseTypeEnum } from '@/lib/models/enums'

type Props = {
  controller: (doOpen: () => void) => ReactNode
  onApplicationTypeSelected: (type: CaseTypeEnum) => void
  renderOnSelected?: ReactNode | null
}

export const SelectApplicationType: FC<Props> = ({ controller, onApplicationTypeSelected, renderOnSelected }) => {
  const { t } = useTranslation()

  return (
    <>
      <BaseModal
        controller={controller}
        title={t('selectApplicationType.title')}
        body={
          <VStack gap={6} w={'full'} alignItems={'flex-start'}>
            {renderOnSelected && renderOnSelected}
            {!renderOnSelected && (
              <>
                <Text>{t('selectApplicationType.subTitle')}</Text>
                <Button
                  w={'full'}
                  colorScheme="facebook"
                  variant={'solid'}
                  onClick={() => onApplicationTypeSelected(CaseTypeEnum.SickLeave)}
                >
                  {t('selectApplicationType.sickLeave')}
                </Button>
                <Button
                  w={'full'}
                  colorScheme="facebook"
                  variant={'solid'}
                  onClick={() => onApplicationTypeSelected(CaseTypeEnum.Care)}
                >
                  {t('selectApplicationType.care')}
                </Button>
                <Box border={'1px'} borderColor={'facebook.300'} w={'full'} borderRadius={'4'}>
                  <Text paddingLeft={'6'} paddingTop={'2'} paddingBottom={'4'}>
                    {t('selectApplicationType.parentalHeader')}
                  </Text>
                  <Center>
                    <VStack gap={6} w={'87%'} alignItems={'flex-start'} paddingBottom={'6'}>
                      <Button
                        w={'full'}
                        colorScheme="facebook"
                        variant={'solid'}
                        onClick={() => onApplicationTypeSelected(CaseTypeEnum.Parental)}
                      >
                        {t('selectApplicationType.parental')}
                      </Button>
                      <Button
                        w={'full'}
                        colorScheme="facebook"
                        variant={'solid'}
                        onClick={() => onApplicationTypeSelected(CaseTypeEnum.LumpSumGrant)}
                      >
                        {t('selectApplicationType.lumpSumGrant')}
                      </Button>
                    </VStack>
                  </Center>
                </Box>
              </>
            )}
          </VStack>
        }
      />
    </>
  )
}
