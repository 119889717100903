import { z } from 'zod'
import { ApplicantModel } from './applicant.model'
import { ApplicationListModel } from './application.model'
import { tannlegeforeningenApi } from './client'

export const getApplicant = async (id: string) => {
  const req = await tannlegeforeningenApi.get(`applicants/${id}`).json()
  return ApplicantModel.parse(req)
}

export const getApplicationsByApplicantId = async (applicantId: string) => {
  const req = await tannlegeforeningenApi.get(`applicants/${applicantId}/applications`).json()
  return z.array(ApplicationListModel).parse(req)
}
