import { z } from 'zod'
import { t } from 'i18next'

export const stringOrUndefined = z
  .string()
  .nullish()
  .transform((x) => {
    if (x === null) return undefined
    if (x === '') return undefined
    return x
  })

export const optionalStringOrUndefined = z.string().optional().or(stringOrUndefined)
export const stringLengthRequired = (x: number) =>
  z
    .string({
      required_error: t('validation.field_required'),
    })
    .trim()
    .min(x, {
      message: t('validation.field_required'),
    })
