import { MultilineText } from '@/components/MultilineText'
import { useGetInfo } from '@/lib/api/info.hook'
import { externalLinks } from '@/lib/externalLinks'
import { formatLandLineNumber } from '@/lib/utility'
import { Box, Container, Divider, Grid, HStack, Link, Stack, VStack, Text } from '@chakra-ui/react'
import { Envelope, Phone } from '@phosphor-icons/react'
import { useTranslation } from 'react-i18next'

export const Footer = () => {
  const { data } = useGetInfo()
  const { t } = useTranslation()

  if (!data) return <Box minHeight={32} />

  return (
    <>
      <Stack w={'full'} textAlign={'left'} alignItems={'center'}>
        <Container
          as={Grid}
          maxW={'container.xl'}
          mt={6}
          mb={12}
          gridTemplateColumns={{
            base: '1fr',
            lg: 'repeat(2, 1fr)',
          }}
          gap={{
            base: 2,
            lg: 4,
          }}
          justifyContent={'center'}
        >
          <Divider gridColumn={'span 2'} gridRow={'1'} marginBottom={4} />
          <VStack
            alignItems={'flex-start'}
            gridColumn={'1 / 2'}
            gridRow={{
              base: '2',
              lg: '2',
            }}
            pr={6}
            mt={{
              base: 2,
              lg: 0,
            }}
            borderColor={'brand.blue'}
          >
            <Text mb={2} fontSize={'1.25rem'} fontWeight={'500'}>
              {t('footer.contactUs')}
            </Text>
            <HStack
              w={'full'}
              alignItems={'flex-start'}
              mr={4}
              gap={0}
              flexDir={{
                base: 'column',
                xl: 'row',
              }}
            >
              <VStack w={'full'} alignItems={'flex-start'} gap={'0.25rem'}>
                <MultilineText
                  textProps={{
                    fontWeight: 'normal',
                  }}
                  text={t('footer.generalTitle')}
                />
                <HStack as={Link} href={`tel:${data?.contactPhone}`} isExternal textDecoration={'none'}>
                  <Phone />
                  <span>+47 {formatLandLineNumber(data?.contactPhone?.replace('+47', ''))}</span>
                </HStack>
                <HStack
                  as={Link}
                  href={`mailto:${data?.contactEmail}`}
                  isExternal
                  rel="noopener noreferrer"
                  textDecoration={'none'}
                >
                  <Envelope />
                  <span>{data?.contactEmail}</span>
                </HStack>
              </VStack>
              <VStack
                w={'full'}
                alignItems={'flex-start'}
                mt={{
                  base: 4,
                  xl: 0,
                }}
                pr={{
                  base: 0,
                  xl: 4,
                }}
              >
                <MultilineText
                  textProps={{
                    fontWeight: 'normal',
                  }}
                  text={t('footer.caseTitle')}
                />
                <Text fontWeight={'300'}>{t('footer.caseText')}</Text>
              </VStack>
            </HStack>
          </VStack>

          <VStack
            w={'auto'}
            alignItems={'flex-start'}
            gap={'0.33rem'}
            gridColumn={{
              base: '1 / 2',
              lg: '2 / 3',
            }}
            gridRow={{
              base: '5',
              lg: '2',
            }}
            ml={{
              base: 0,
              lg: 16,
            }}
          >
            <Text fontWeight={'500'} fontSize={'1.25rem'} mb={2}>
              {t('footer.links')}
            </Text>
            <Link textDecoration={'none'} href={externalLinks.sykehjelpsordningen} isExternal>
              <span>Sykehjelpsordningen</span>
            </Link>
            <Link textDecoration={'none'} href={externalLinks.tannlegeforeningen} isExternal>
              <span>Den norske tannlegeforening</span>
            </Link>
            <Link textDecoration={'none'} href={externalLinks.privacyAgreement} isExternal>
              <span>Personvern for sykehjelpsordningen </span>
            </Link>
          </VStack>
        </Container>
      </Stack>
    </>
  )
}
