import { useGetApplicant } from '@/lib/api'
import { ApplicantModel } from '@/lib/api/applicant.model'
import { useGetApplicationsByApplicantId } from '@/lib/api/applicant.hook'
import { ApplicationListModel } from '@/lib/api/application.model'
import { useMe } from '@/useMe'
import { FC, PropsWithChildren, createContext, useEffect, useMemo, useReducer } from 'react'
import { useSessionStorage } from 'usehooks-ts'

export const ApplicationContext = createContext<ContextProvider | null>(null)

type ApplicationState = {
  applicant: ApplicantModel | undefined
  applications: ApplicationListModel[]
  sidebarOpen?: boolean
}

type ContextProvider = {
  state: ApplicationState
  dispatch: (action: Actions) => void
}

type Action<T, K> = {
  action: T
  payload: K
}

type Actions =
  | Action<'SET_APPLICANT', ApplicantModel>
  | Action<'REMOVE_APPLICANT', ApplicantModel>
  | Action<'SET_APPLICATIONS', ApplicationListModel[]>
  | Action<'SET_SIDEBAR_STATE', boolean>

const applicationStateReducer = (state: ApplicationState, input: Actions) => {
  switch (input.action) {
    case 'SET_APPLICANT':
      return {
        ...state,
        applicant: input.payload,
      }
    case 'REMOVE_APPLICANT':
      return {
        ...state,
        applicant: undefined,
      }
    case 'SET_APPLICATIONS':
      return {
        ...state,
        applications: input.payload,
      }
    case 'SET_SIDEBAR_STATE':
      return {
        ...state,
        sidebarOpen: input.payload,
      }
    default:
      return state
  }
}

const initialState: ApplicationState = {
  applicant: undefined,
  applications: [],
  sidebarOpen: false,
}

export const ApplicationContextProvider: FC<PropsWithChildren> = ({ children }) => {
  const [state, dispatch] = useReducer(applicationStateReducer, initialState)
  const [isLoggedIn, setIsLoggedIn] = useSessionStorage('isLoggedIn', false)

  const { data: loggedInUser, isError } = useMe({
    retry: false,
    enabled: isLoggedIn,
  })
  const { data: applicant } = useGetApplicant(loggedInUser?.id ?? '')
  const { data: applications } = useGetApplicationsByApplicantId(loggedInUser?.id ?? '')

  useEffect(() => {
    if (applications && applications.length) {
      dispatch({ action: 'SET_APPLICATIONS', payload: applications })
    }
  }, [applications])

  useEffect(() => {
    if (applicant) {
      dispatch({ action: 'SET_APPLICANT', payload: applicant })
    }
  }, [applicant])

  useEffect(() => {
    if (isError) {
      setIsLoggedIn(false)
    }
  }, [isError, setIsLoggedIn])

  const store = useMemo(() => {
    return {
      state,
      dispatch,
    }
  }, [state])

  return <ApplicationContext.Provider value={store}>{children}</ApplicationContext.Provider>
}
