import { extendTheme } from '@chakra-ui/react'
import { alertTheme } from './alertTheme'
import { buttonTheme } from './buttonTheme'
import { checkboxTheme } from './checkboxTheme'
import { formTheme } from './formTheme'
import { inputTheme } from './inputTheme'
import { popoverTheme } from './popoverTheme'
import { radioTheme } from './radioTheme'
import { selectTheme } from './selectTheme'
import { tableTheme } from './tableTheme'
import { tooltipTheme } from './tooltipTheme'
import { modalTheme } from './modalTheme'
import { colors } from './colors'

const borderRadius = {
  radii: {
    none: '0',
    sm: '0.1875rem',
    base: '0.25rem',
    md: '0.375rem',
    lg: '0.5rem',
    xl: '0.75rem',
    '2xl': '1rem',
    '3xl': '1.5rem',
    full: '9999px',
  },
}
export const theme = extendTheme({
  ...borderRadius,
  colors,
  components: {
    FormError: {
      baseStyle: {
        justifyContent: 'flex-start',
        text: {
          color: 'facebook',
        },
      },
    },
    Alert: alertTheme,
    Button: buttonTheme,
    Checkbox: checkboxTheme,
    Form: formTheme,
    Heading: {
      baseStyle: {
        fontWeight: 500,
      },
      variants: {
        widget: {
          fontSize: '2.125rem',
          fontWeight: 500,
          lineHeight: '140%',
          textWrap: 'balance',
        },
        widgetSmall: {
          fontSize: '1.75rem',
          fontWeight: 500,
          lineHeight: '140%',
          textWrap: 'balance',
        },
      },
    },
    Input: inputTheme,
    Link: {
      baseStyle: {
        textDecoration: 'underline',
      },
      variants: {
        widget: {
          fontSize: '1.25rem',
          fontWeight: 400,
          lineHeight: '168%',
        },
      },
    },
    Modal: modalTheme,
    Popover: popoverTheme,
    Radio: radioTheme,
    Select: selectTheme,
    Table: tableTheme,
    Toast: {
      baseStyle: {
        background: '#32E58F',
      },
    },
    Text: {
      baseStyle: {
        fontWeight: 300,
      },
      variants: {
        formHeader: {
          fontWeight: 500,
          lineHeight: '187%',
          fontSize: '1.275rem',
        },
        formSubHeader: {
          fontWeight: 500,
          lineHeight: '187%',
          fontSize: '1.125rem',
        },
        warning: {
          fontStyle: 'italic',
          fontSize: '1.2rem',
          fontWeight: 300,
          lineHeight: '140%',
          marginBlock: '2rem',
        },
        widget: {
          fontSize: '1.5rem',
          fontWeight: 300,
          lineHeight: '140%',
          marginBlock: '2rem',
        },
        widgetSmall: {
          fontSize: '1rem',
          fontWeight: 300,
          lineHeight: '120%',
          marginBlock: '2rem',
        },
      },
    },
    Textarea: {
      variants: {
        outline: {
          background: 'white',
          borderColor: 'brand.blue',
          borderRadius: 'sm',
        },
      },
    },
    Tooltip: tooltipTheme,
  },
  fonts: {
    body: 'Roboto',
    heading: 'Roboto',
  },
  styles: {
    global: {
      'html, body': {
        color: colors.brand.blue,
      },
    },
  },
})
