export const ExtensionMimeTypes = [
  {
    mime: 'image/jpeg',
    ext: '.jpeg',
  },
  {
    mime: 'image/jpg',
    ext: '.jpg',
  },
  {
    mime: 'image/png',
    ext: '.png',
  },
  {
    mime: 'application/pdf',
    ext: '.pdf',
  },
  // {
  //   mime: 'application/msword',
  //   ext: '.doc',
  // },
  // {
  //   mime: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  //   ext: '.docx',
  // },
  // {
  //   mime: 'application/vnd.ms-excel',
  //   ext: '.xls',
  // },
  // {
  //   mime: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  //   ext: '.xlsx',
  // },
  // {
  //   mime: 'image/tiff',
  //   ext: '.tiff',
  // },
] as const

export const allowedExtensions = ExtensionMimeTypes.map((extension) => extension.ext)
export type FileType = (typeof ExtensionMimeTypes)[number]
export type Extension = (typeof ExtensionMimeTypes)[number]['ext']

export const getExtensionMime = (extensions: Extension[]): FileType[] => {
  return ExtensionMimeTypes.filter((extension) => {
    return extensions.includes(extension.ext)
  })
}

export const fileDownloader = (data: Blob) => {
  data && window.open(URL.createObjectURL(data), '_blank')
}
