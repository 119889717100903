import { radioAnatomy } from '@chakra-ui/anatomy'
import { createMultiStyleConfigHelpers } from '@chakra-ui/react'

const helper = createMultiStyleConfigHelpers(radioAnatomy.keys)

const baseStyle = helper.definePartsStyle({
  control: {
    WebkitAppearance: 'none',
    appearance: 'none',
    borderColor: 'brand.blue',
    borderWidth: '1px',
    width: '1.1em',
    height: '1.1em',
    background: 'white',
    margin: '0',
    placeContent: 'center',
    font: 'inherit',
    transform: 'translateY(-0.075em)',
    _checked: {
      borderColor: 'brand.blue',
      color: 'brand.blue',
      backgroundColor: 'brand.lightGray',
      '::before': {
        content: '""',
        borderRadius: '50%',
        boxShadow: 'inset 0 0 0 0.5em var(--chakra-colors-brand-blue)',
        width: '0.65em',
        height: '0.65em',
      },
    },
    _invalid: {
      borderColor: 'brand.warning',
    },
  },
})

export const radioTheme = helper.defineMultiStyleConfig({
  baseStyle,
})
