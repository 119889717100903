import { ApplicationInsights } from '@microsoft/applicationinsights-web'
import { ReactPlugin } from '@microsoft/applicationinsights-react-js'
import { env } from '@/config/env'

export const appInsightReactPlugin = new ReactPlugin()

export const startInsight = () => {
  const connectionString = env.VITE_APP_INSIGHT_CONNECTION_STRING

  if (!connectionString) return

  const i = new ApplicationInsights({
    config: {
      enableDebug: true,
      connectionString,
      extensions: [appInsightReactPlugin],
    },
  })

  i.loadAppInsights()
}
