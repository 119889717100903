export const formatAccountNumber = (input: string | undefined) => {
  if (!input) return ''
  const parts = `${input}`.split('')
  return `${parts.slice(0, 4).join('')} ${parts.slice(4, 6).join('')} ${parts.slice(6).join('')}`
}

export const formatSplitSections = (input: string | undefined, segments: number[]) => {
  if (!input) return ''

  const parts = `${input.replaceAll(' ', '')}`.split('')

  let result = ''
  const breakpoints = segments.reduce((acc: number[], val: number, i: number) => {
    if (i === 0) {
      acc.push(val)
    } else {
      acc.push(acc[i - 1] + val)
    }
    return acc
  }, [])

  breakpoints.sort((a, b) => a - b)

  let lastBreakpoint = 0
  for (let i = 0; i < breakpoints.length; i++) {
    const currentBreakPoint = breakpoints[i]
    result += parts.slice(lastBreakpoint, currentBreakPoint).join('') + ' '
    lastBreakpoint = currentBreakPoint
  }

  return result.trim()
}

export const NokIntl = new Intl.NumberFormat('nb-NO', {
  // style: 'currency',
  currency: 'NOK',
  // currencyDisplay: '',
  maximumFractionDigits: 0,
})

export const ToNoK = (value: number) => `kr ` + NokIntl.format(value)

export const formatNOK = (value?: string) => {
  if (typeof value === 'number') return NokIntl.format(value)
  if (!value?.trim?.()) return ''
  value = value?.replace?.(/\D/g, '').trim()
  value = value?.replace?.('kr ', '')
  return NokIntl.format(Number(value))
}
export const formatMoney = (input?: string | number, showZero: boolean = false) => {
  const format = formatNOK(`${input}`)
  if (format == '0' && !showZero) return ''
  return `kr ${format}`
}
export const formatPhoneNumber = (input?: string) => formatSplitSections(input, [3, 2, 3])
export const formatLandLineNumber = (input?: string) => formatSplitSections(input, [2, 2, 2, 2])
export const formatPersonNumber = (input?: string) => formatSplitSections(input, [6, 5])

export const removeMoneyFormat = (input?: string | number) => {
  if (!input) return undefined
  if (typeof input === 'number') return input
  return input.replace(/\D/g, '')
}
