import { useContext } from 'react'
import { ApplicationContext } from './index'

export const useApplicationContext = () => {
  const ctx = useContext(ApplicationContext)
  if (!ctx) {
    throw new Error('useApplicationContext must be used within a ApplicationContextProvider')
  }
  return ctx
}
