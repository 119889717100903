import { Heading } from '@chakra-ui/react'
import { RouterProvider } from '@tanstack/react-router'
import '@total-typescript/ts-reset'
import React, { Suspense } from 'react'
import ReactDOM from 'react-dom/client'
import { ErrorBoundary } from 'react-error-boundary'
import { CenterCenter } from './components/CenterCenter'
import { Loading } from './components/Loading'
import { router } from './Router'
import { QueryProvider } from './features/query'
import { ThemeProvider } from './features/theme'
import lng from './i18n/config'
import { AppInsightsErrorBoundary } from '@microsoft/applicationinsights-react-js'
import { appInsightReactPlugin, startInsight } from './lib/AppInsights'

lng.on('initialized', () => {
  startInsight()

  ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
    <React.StrictMode>
      <AppInsightsErrorBoundary
        onError={() => {
          return <Heading>En feil har oppstått</Heading>
        }}
        appInsights={appInsightReactPlugin}
      >
        <QueryProvider>
          <ThemeProvider>
            <Suspense fallback={<Loading />}>
              <ErrorBoundary
                fallback={
                  <CenterCenter>
                    <Heading>En feil har oppstått</Heading>
                  </CenterCenter>
                }
              >
                <RouterProvider router={router} />
              </ErrorBoundary>
            </Suspense>
          </ThemeProvider>
        </QueryProvider>
      </AppInsightsErrorBoundary>
    </React.StrictMode>
  )
})
