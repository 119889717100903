import { modalAnatomy as parts } from '@chakra-ui/anatomy'
import { createMultiStyleConfigHelpers } from '@chakra-ui/react'
const helper = createMultiStyleConfigHelpers(parts.keys)

const baseStyle = helper.definePartsStyle({
  dialog: {
    minHeight: 400,
    borderRadius: 3,
  },
  header: {
    pt: 6,
  },
  footer: {
    mb: 2,
    justifyContent: 'start',
  },
})

export const modalTheme = helper.defineMultiStyleConfig({
  baseStyle,
})
