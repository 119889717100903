import { useApplicationContext } from '@/context/ApplicationContext'
import { doLogout } from '@/lib/utility/logout'
import { Box, Text } from '@chakra-ui/react'
import { useEffect, useState } from 'react'

const tracker: {
  lastActivity: number
  sessionTimer: number | undefined
} = {
  lastActivity: Date.now(),
  sessionTimer: undefined,
} as const

const hasExpiredSession = () => getInactiveTimeInSeconds(tracker.lastActivity) > sessionTimeInSeconds

const sessionTimeInSeconds = 1800

const getTime = () => tracker.lastActivity

const registerActivity = async () => {
  if (hasExpiredSession()) {
    return doLogout()
  }
  tracker.lastActivity = Date.now()
}

const getInactiveTimeInSeconds = (lastActivityInMs: number) => {
  return Math.ceil((Date.now() - lastActivityInMs) / 1000)
}
const secondsToMinSecPadded = (time: number) => {
  const minutes = `${Math.floor(time / 60)}`.padStart(2, '0')
  const seconds = `${time % 60}`.padStart(2, '0')
  return `${minutes}:${seconds}`
}

export const ActivityTracker = () => {
  const {
    state: { applicant },
  } = useApplicationContext()

  const [timeLeft, setTimeLeft] = useState<string | undefined>()

  // Effect to track user activity so we can keep the user authenticated.
  useEffect(() => {
    const eventsThatTracksUserActivity = ['mousedown', 'mousemove', 'keydown', 'scroll', 'touchstart']

    let timer = 0

    if (applicant) {
      timer = window.setInterval(() => {
        const timeLeft = sessionTimeInSeconds - getInactiveTimeInSeconds(getTime())

        if (timeLeft > 1790) {
          setTimeLeft(undefined)
        } else {
          setTimeLeft(secondsToMinSecPadded(timeLeft))
        }

        if (timeLeft <= 1) {
          clearInterval(timer)
          doLogout()
        }
      }, 1000)

      eventsThatTracksUserActivity.forEach(function (eventName) {
        document.addEventListener(eventName, registerActivity, true)
      })
    }

    return () => {
      clearInterval(timer)
      eventsThatTracksUserActivity.forEach(function (eventName) {
        document.removeEventListener(eventName, registerActivity, true)
      })
    }
  }, [applicant])

  if (!applicant) return null

  return (
    <Box position={'absolute'} top={2} right={2} rounded={'md'} py={1} px={3} opacity={0.8}>
      <Text color="brand.blue">{timeLeft}</Text>
    </Box>
  )
}
