import { ComponentStyleConfig } from '@chakra-ui/react'

export const formTheme: ComponentStyleConfig = {
  parts: ['container', 'requiredIndicator', 'helperText'],
  baseStyle: {
    container: {
      label: {
        fontWeight: 400,
      },
    },
  },
}
