import { popoverAnatomy as parts } from '@chakra-ui/anatomy'
import { createMultiStyleConfigHelpers } from '@chakra-ui/react'
const helper = createMultiStyleConfigHelpers(parts.keys)
const extraInformation = helper.definePartsStyle({
  arrow: {
    '--popper-arrow-bg': 'var(--chakra-colors-brand-blue)',
  },
  content: {
    color: 'white',
    bg: 'brand.blue',
    padding: '1.25rem',
    borderRadius: '3px',
  },
})
export const popoverTheme = helper.defineMultiStyleConfig({ variants: { extraInformation } })
