import { Button, Heading, Spinner, Stack, Text, VStack } from '@chakra-ui/react'
import { ArrowRight } from '@phosphor-icons/react'
import { FC } from 'react'
import { Widget } from '../../components/Widget'
import { SelectApplicationType } from '@/features/SelectApplicationType'
import {
  manualCaregiverRoute,
  manualParentalRoute,
  manualSickLeaveRoute,
  manualParentalLumpSumGrantRoute,
} from '@/Router'
import { useTranslation } from 'react-i18next'
import { CaseTypeEnum } from '@/lib/models/enums'
import { useNavigate } from '@tanstack/react-router'
import { useCreateApplication } from '@/lib/api/application.hook'
import { useUserMessage } from '@/hooks'

export const ManualApplicationBox: FC = () => {
  const { t } = useTranslation()
  const { toastInfo } = useUserMessage()

  const navigateToApplication = useNavigate()

  const {
    mutateAsync: createApplication,
    isPending: isCreatingApplication,
    isSuccess: applicationCreated,
  } = useCreateApplication()

  const newApplication = async (type: CaseTypeEnum) => {
    const { id } = await createApplication({
      body: {
        caseType: type,
      },
    })
    return id
  }

  const doCreateApplication = async (type: CaseTypeEnum) => {
    try {
      let path:
        | (typeof manualSickLeaveRoute)['fullPath']
        | (typeof manualCaregiverRoute)['fullPath']
        | (typeof manualParentalRoute)['fullPath']
        | (typeof manualParentalLumpSumGrantRoute)['fullPath']
        | undefined = undefined

      switch (type) {
        case CaseTypeEnum.SickLeave:
          path = manualSickLeaveRoute.fullPath
          break
        case CaseTypeEnum.Care:
          path = manualCaregiverRoute.fullPath
          break
        case CaseTypeEnum.Parental:
          path = manualParentalRoute.fullPath
          break
        case CaseTypeEnum.LumpSumGrant:
          path = manualParentalLumpSumGrantRoute.fullPath
          break
      }

      const id = await newApplication(type)
      void navigateToApplication({
        to: path,
        search: {
          id,
          state: 'info',
        },
      })
    } catch (error) {
      toastInfo('Kunne ikke opprette søknad, prøv igjen senere.')
    }
  }

  return (
    <Widget display={'flex'} flexDir={'column'} justifyContent={'space-between'} w={'full'}>
      <Stack height={'full'} justifyContent={'space-between'}>
        <Stack height={'fit-content'} gap={0}>
          <Heading variant={'widgetSmall'}>{t('applicationInformationPage.manualApplicationBox.title')}</Heading>
          <Text maxW={'80ch'} variant={'widgetSmall'} fontSize={'1rem'}>
            {t('applicationInformationPage.manualApplicationBox.content')}
          </Text>
        </Stack>
        <SelectApplicationType
          onApplicationTypeSelected={doCreateApplication}
          renderOnSelected={
            isCreatingApplication || applicationCreated ? (
              <VStack spacing={4} w={'full'} justifyContent={'center'} alignItems={'center'}>
                <Spinner size={'xl'} />
                <Text>{t('applicationInformationPage.creatingApplication')}</Text>
              </VStack>
            ) : null
          }
          controller={(doOpen) => (
            <Button
              rightIcon={<ArrowRight />}
              variant={'solid'}
              colorScheme="facebook"
              onClick={doOpen}
              width={{
                base: 'full',
                md: 'fit-content',
              }}
            >
              {t('applicationInformationPage.startManualApplication')}
            </Button>
          )}
        />
      </Stack>
    </Widget>
  )
}
