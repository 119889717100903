export const toDateString = (date: string | Date, locale: string = 'nb-NO') =>
  new Date(date).toLocaleDateString(locale, { day: '2-digit', month: '2-digit', year: 'numeric' })

export const isoYMDstring = (date?: Date | null) => {
  if (!date) return ''
  const d = new Date(date)
  const year = d.getFullYear()
  const month = `${d.getMonth() + 1}`.padStart(2, '0')
  const day = `${d.getDate()}`.padStart(2, '0')
  return `${year}-${month}-${day}`
}

export const dateToDateTimeText = new Intl.DateTimeFormat('nb-NO', {
  dateStyle: 'long',
  timeStyle: 'short',
  timeZone: 'Europe/Oslo',
})

export const dateToDateText = new Intl.DateTimeFormat('nb-NO', {
  dateStyle: 'long',
  timeZone: 'Europe/Oslo',
})

export const monthYearOnly = new Intl.DateTimeFormat('nb-NO', {
  month: 'long',
  year: 'numeric',
  timeZone: 'Europe/Oslo',
})
