/* eslint-disable max-lines */
import { BaseModal } from '@/components/modals/BaseModal'
import { useUserMessage } from '@/hooks'
import { useGetApplicationMessages, useMarkMessageAsRead, usePostMessage } from '@/lib/api/application.hook'
import { ApplicationMessageModel } from '@/lib/api/application.model'
import { CaseStatusEnum, CaseType, CaseTypeEnum } from '@/lib/models/enums'
import { dateToDateText, dateToDateTimeText, getStatusText } from '@/lib/utility'
import {
  Button,
  Center,
  Circle,
  Collapse,
  Divider,
  Flex,
  Grid,
  HStack,
  Heading,
  Spinner,
  Text,
  Textarea,
  VStack,
  useToken,
} from '@chakra-ui/react'
import { Envelope, EnvelopeOpen } from '@phosphor-icons/react'
import { FC, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

type ApplicationMessageProps = {
  status: CaseStatusEnum
  createdOn: Date
  caseType: CaseTypeEnum
  caseNumber: string
  hasUnreadMessages: boolean
  applicationId: string
}

export const ApplicationMessage: FC<ApplicationMessageProps> = ({
  applicationId,
  caseType,
  createdOn,
  status,
  caseNumber,
  hasUnreadMessages,
}) => {
  const brandToken = useToken('colors', 'brand.blue')

  return (
    <BaseModal
      modalProps={{
        size: '4xl',
      }}
      body={<MessageBody applicationId={applicationId} createdOn={createdOn} caseType={caseType} caseStatus={status} />}
      controller={(onOpen) => (
        <Button
          variant={'ghost'}
          p={0}
          m={0}
          minH={0}
          height={'30px'}
          position={'relative'}
          textDecor={'underline'}
          color={'white !important'}
          minW={'63px'}
          textAlign={'left'}
          transition={'all 0.2s'}
          _hover={{
            transform: 'scale(1.1)',
            bg: 'transparent',
          }}
          _focus={{
            bg: 'transparent',
          }}
          onClick={(x) => {
            x.preventDefault()
            x.bubbles = false
            x.stopPropagation()
            onOpen()
          }}
        >
          <Circle>
            {(hasUnreadMessages && <Envelope fontWeight={'bold'} size={26} color={brandToken} />) || (
              <EnvelopeOpen fontWeight={'bold'} size={26} color={brandToken} />
            )}
          </Circle>
          {hasUnreadMessages && (
            <Circle position={'absolute'} top={-1} right={4} size="2" bg="#ff4444" color="white"></Circle>
          )}
        </Button>
      )}
      title={
        <HStack
          flexDir={{
            base: 'column',
            md: 'row',
          }}
          w={'full'}
          justifyContent={'space-between'}
          alignItems={'flex-start'}
          pr={6}
        >
          <Text fontWeight={'500'}>{`Sak #${caseNumber}`}</Text>
        </HStack>
      }
    ></BaseModal>
  )
}

type MessageBodyProps = {
  applicationId: string
  caseType: CaseTypeEnum
  caseStatus: CaseStatusEnum
  createdOn: Date
}
const MessageBody: FC<MessageBodyProps> = ({ createdOn, caseType, applicationId, caseStatus }) => {
  const { data: messages, isLoading } = useGetApplicationMessages(applicationId)

  const { mutate: doMarkMessageAsRead } = useMarkMessageAsRead()

  useEffect(() => {
    if (!messages) return
    const hasUnreadMessages = messages.some((x) => !x.isReadByApplicant)
    if (hasUnreadMessages) {
      doMarkMessageAsRead(applicationId)
    }
  }, [messages, applicationId, doMarkMessageAsRead])

  return (
    <Grid
      gridTemplateColumns={{
        base: '100%',
      }}
    >
      <Header caseType={caseType} createdOn={createdOn} caseStatus={caseStatus} />
      <Divider my={6} />
      <MessageHeader applicationId={applicationId} />
      <Messages messages={messages} isLoading={isLoading} />
    </Grid>
  )
}

type HeaderProps = {
  createdOn: Date
  caseType: CaseTypeEnum
  caseStatus: CaseStatusEnum
}
const Header: FC<HeaderProps> = ({ createdOn, caseType, caseStatus }) => {
  const { t } = useTranslation()
  return (
    <Flex
      gap={{
        base: 2,
        md: 8,
      }}
      flexDir={{
        base: 'column',
        md: 'row',
      }}
    >
      <Stat title={'Sendt'} text={dateToDateText.format(createdOn)} />
      <Stat title={'Type'} text={t(CaseType[caseType] as 'selectApplicationType.sickLeave')} />
      <Stat title="Status" text={getStatusText({ status: caseStatus })}></Stat>
    </Flex>
  )
}

const MessageHeader = ({ applicationId }: { applicationId: string }) => {
  const { t } = useTranslation()
  const [writeMessage, setWriteMessage] = useState(false)
  const [message, setMessageText] = useState<string>('')
  const { mutate: doPostMessage, isPending } = usePostMessage()
  const { toastSuccess, toastInfo } = useUserMessage()

  const handlePostMessage = async () => {
    doPostMessage(
      { applicationId, message },
      {
        onSuccess: () => {
          toastSuccess('Melding er sendt')
          setMessageText('')
          setWriteMessage(false)
        },
        onError: () => {
          toastInfo('Kunne ikke sende melding, prøv igjen.')
        },
      }
    )
  }

  return (
    <>
      <HStack
        flexDir={{
          base: 'column',
          md: 'row',
        }}
        gap={4}
        justifyContent={'space-between'}
        alignItems={'flex-start'}
        pb={8}
      >
        <Heading size={'md'}>{t('messages.subTitle')}</Heading>

        <Button onClick={() => setWriteMessage((x) => !x)} variant={'link'}>
          {t('messages.addMessage')}
        </Button>
      </HStack>
      <Collapse in={writeMessage}>
        <VStack mb={10}>
          <Text alignSelf={'flex-start'}>{t('messages.newMessage')}</Text>
          <Textarea
            value={message}
            onChange={(e) => setMessageText(e.target.value)}
            rows={5}
            maxLength={1000}
            placeholder="Melding til saksbehandler"
          ></Textarea>
          <Grid
            templateColumns={{
              base: '1fr',
              md: '135px 135px 1fr',
            }}
            gap={4}
            alignSelf={'flex-start'}
            w={'full'}
          >
            <Button
              minW={'130px'}
              onClick={handlePostMessage}
              isDisabled={!message || isPending}
              colorScheme="facebook"
              alignSelf={'flex-end'}
            >
              {(isPending && <Spinner size="xs" />) || t('messages.send')}
            </Button>
            <Button
              onClick={() => setWriteMessage(false)}
              colorScheme="facebook"
              variant={'outline'}
              alignSelf={'flex-end'}
            >
              {t('common.cancel')}
            </Button>
            <Text justifySelf={'flex-end'}>{message?.length ?? 0} / 1000</Text>
          </Grid>
          <Divider
            my={{
              base: 2,
              md: 4,
            }}
          />
        </VStack>
      </Collapse>
    </>
  )
}

const Stat = ({ title, text }: { title: string; text: string }) => {
  return (
    <VStack alignItems={'flex-start'} gap={0}>
      <Text>{title}</Text>
      <Text fontSize={'lg'} fontWeight={'500'}>
        {text}
      </Text>
    </VStack>
  )
}

type MessagesProps = {
  messages?: ApplicationMessageModel[]
  isLoading?: boolean
}
const Messages: FC<MessagesProps> = ({ messages, isLoading }) => {
  const { t } = useTranslation()

  if (isLoading) {
    return (
      <Center>
        <Spinner />
      </Center>
    )
  }

  if (!messages || !messages.length) {
    return (
      <Center bg={'gray.100'} p={6}>
        <Text fontStyle={'italic'} opacity={'0.6'}>
          {t('messages.noMessages')}
        </Text>
      </Center>
    )
  }

  return (
    <VStack w={'full'} gap={3}>
      {messages
        .sort((a, b) => b.createdOn.getTime() - a.createdOn.getTime())
        .map((message) => (
          <Message key={message.id} {...message} />
        ))}
    </VStack>
  )
}

type MessageProps = {
  writtenBy?: string
  createdOn: Date
  isFromApplicant: boolean
  message: string
}
const Message: FC<MessageProps> = ({ message, createdOn, writtenBy, isFromApplicant }) => {
  const alignment = isFromApplicant ? 'flex-end' : 'flex-start'
  // Clean message for HTML tags
  message = message.replace(/<[^>]*>?/gm, '')
  message = message.replace(/onclick="[^"]*"/gm, '')
  message = message.replace(/javascript:/gm, '')

  return (
    <VStack
      alignSelf={alignment}
      maxW={{
        base: '100%',
        md: '80%',
      }}
      minW={'40%'}
      p={4}
      bg={isFromApplicant ? 'gray.100' : 'gray.200'}
      alignItems={alignment}
      borderRadius={4}
      gap={4}
    >
      <HStack
        justifyContent={alignment}
        alignItems={alignment}
        w={'full'}
        flexDir={{
          base: 'column',
          md: 'row',
        }}
      >
        <Text fontWeight={'400'}>{writtenBy}</Text>
        <Text>{dateToDateTimeText.format(createdOn)}</Text>
      </HStack>
      <Text
        as={Flex}
        width={'100%'}
        sx={{
          '.table-container': {
            width: '100%',
            overflowX: 'auto',
          },
          table: {
            minWidth: '500px',
            textAlign: 'left',
            borderCollapse: 'collapse',
            my: 4,
            th: {
              fontWeight: '400',
              '&:nth-child(1)': {
                width: '130px',
              },
              '&:nth-child(2)': {
                width: '85px',
                textAlign: 'right',
              },
              '&:nth-child(3)': {
                textAlign: 'right',
                minWidth: '60px',
              },
              '&:nth-child(4), &:nth-child(5)': {
                textAlign: 'right',
              },
              '&:nth-child(5)': {
                textAlign: 'right',
                minWidth: '80px',
              },
            },
            td: {
              '&:nth-child(2)': {
                textAlign: 'right',
              },
              '&:nth-child(3)': {
                textAlign: 'right',
              },
              '&:nth-child(4), &:nth-child(5)': {
                textAlign: 'right',
              },
            },
          },
        }}
        flexDir={'column'}
        gap={3}
        dangerouslySetInnerHTML={{
          __html: CreateText(message),
        }}
      />
    </VStack>
  )
}

const CreateText = (text: string) => {
  let isInTable = false

  return text
    .split('\n')
    .map((x) => {
      const content = ReplaceBBCode(x)

      if (x.trim().length <= 1) return ``

      if (x.includes('[table]') && x.includes('[/table]')) {
        return `<div class='table-container'>` + content + '</div>'
      }

      if (x.includes('[/table]')) {
        isInTable = false
        return content + '</div>'
      }
      if (x.includes('[table]')) {
        isInTable = true
        return '<div>' + content
      }

      if (isInTable) {
        return content
      }

      return `<p>${content}</p>`
    })
    .join('')
}

const ReplaceBBCode = (text: string) => {
  text = text.replaceAll(/\[h]/g, '<strong>').replaceAll(/\[\/h]/g, '</strong>')
  text = text.replaceAll(/\[b]/g, `<strong style="font-weight:400;">`).replaceAll(/\[\/b]/g, '</strong>')
  text = text.replaceAll(/\[table]/g, '<table>').replaceAll(/\[\/table]/g, '</table>')
  text = text.replaceAll(/\[thead]/g, '<thead>').replaceAll(/\[\/thead]/g, '</thead>')
  text = text.replaceAll(/\[tbody]/g, '<tbody>').replaceAll(/\[\/tbody]/g, '</tbody>')
  text = text.replaceAll(/\[tr]/g, '<tr>').replaceAll(/\[\/tr]/g, '</tr>')
  text = text.replaceAll(/\[th]/g, '<th>').replaceAll(/\[\/th]/g, '</th>')
  text = text.replaceAll(/\[td]/g, '<td>').replaceAll(/\[\/td]/g, '</td>')
  text = text.replaceAll(/\[br]/g, '<br />')

  return text.trim()
}
