export const CaseType: { [key in CaseTypeEnum]: string } = {
  935000000: 'selectApplicationType.sickLeave',
  935000001: 'selectApplicationType.parental',
  935000002: 'selectApplicationType.care',
  935000003: 'selectApplicationType.lumpSumGrant',
}

export enum CaseTypeEnum {
  SickLeave = 935000000,
  Parental = 935000001,
  Care = 935000002,
  LumpSumGrant = 935000003,
}
