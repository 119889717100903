import { useQuery } from '@tanstack/react-query'
import { getInfo } from './info.api'

export const useGetInfo = () => {
  return useQuery({
    queryKey: ['info'],
    queryFn: () => getInfo(),
    enabled: true,
    retry: false,
    staleTime: Infinity,
  })
}
