export const EmploymentType: { [key in EmploymentTypeEnum]: string } = {
  935000000: 'Enkeltpersonforetak',
  935000001: 'Privatvirksomhet',
  935000002: 'Eget AS',
  935000003: 'Offentlig tjeneste',
  935000004: 'Uførepensjon',
}

export enum EmploymentTypeEnum {
  Enkeltpersonforetak = 935000000,
  Privatvirksomhet = 935000001,
  EgetAS = 935000002,
  Offentligtjeneste = 935000003,
  Ufrepensjon = 935000004,
}

export const Employments = {
  selfEmployedENK: 'selfEmployedENK',
  employeePrivate: 'employeePrivate',
  selfEmployedAS: 'selfEmployedAS',
  employeePublic: 'employeePublic',
} as const

export const employmentFromEnum = (employmentType: EmploymentTypeEnum) => {
  switch (employmentType) {
    case EmploymentTypeEnum.Enkeltpersonforetak:
      return Employments.selfEmployedENK
    case EmploymentTypeEnum.Privatvirksomhet:
      return Employments.employeePrivate
    case EmploymentTypeEnum.EgetAS:
      return Employments.selfEmployedAS
    case EmploymentTypeEnum.Offentligtjeneste:
      return Employments.employeePublic
  }
}
