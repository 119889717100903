export const LeaveAppliesTo: { [key in LeaveAppliesToEnum]: string } = {
  935000000: 'Barn',
  935000001: 'Pårørende',
}

export enum LeaveAppliesToEnum {
  Child = 935000000,
  Relative = 935000001,
}

export const LeaveAppliesTos = {
  selfEmployedENK: 'selfEmployedENK',
  employeePrivate: 'employeePrivate',
} as const

export const leaveAppliesToFromEnum = (employmentType: LeaveAppliesToEnum) => {
  switch (employmentType) {
    case LeaveAppliesToEnum.Child:
      return LeaveAppliesTos.selfEmployedENK
    case LeaveAppliesToEnum.Relative:
      return LeaveAppliesTos.employeePrivate
  }
}
