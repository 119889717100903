export const GenderOptions = ['Mann', 'Kvinne'] as const

export const Gender: { [key in GenderEnum]: (typeof GenderOptions)[number] } = {
  935000000: 'Mann',
  935000001: 'Kvinne',
} as const

export enum GenderEnum {
  Mann = 935000000,
  Kvinne = 935000001,
}
