/* eslint-disable react-refresh/only-export-components */
import { Outlet, RootRoute, Route, Router, lazyRouteComponent } from '@tanstack/react-router'
import { lazy } from 'react'
import { assign, fromPromise } from 'xstate'
import { Skeleton } from './features/skeleton'
import { SykehjelpsOrdningenMachineContext, sykehjelpsOrdningenMachine } from './sykehjelpsOrdningen.machine'
import { ApplicationContextProvider } from './context/ApplicationContext'
import { z } from 'zod'
import { HomePage } from './pages/Home.page.tsx'
import { ActivityTracker } from './components/ActivityTracker.tsx'

const TanStackRouterDevtools = import.meta.env.PROD
  ? () => null // Render nothing in production
  : lazy(() =>
      // Lazy load in development
      import('@tanstack/router-devtools').then((res) => ({
        default: res.TanStackRouterDevtools,
      }))
    )

const Root = () => {
  return (
    <ApplicationContextProvider>
      <ActivityTracker />
      <Skeleton>
        <SykehjelpsOrdningenMachineContext.Provider
          logic={sykehjelpsOrdningenMachine.provide({
            actions: {
              setConcent: assign({
                concent: () => true,
              }),
            },
            actors: {
              bankIdLogin: fromPromise(() => delay(1000)),
            },
          })}
        >
          <Outlet />
        </SykehjelpsOrdningenMachineContext.Provider>
        <TanStackRouterDevtools position="top-left" />
      </Skeleton>
    </ApplicationContextProvider>
  )
}

const rootRoute = new RootRoute({
  component: Root,
})

export const homeRoute = new Route({
  getParentRoute: () => rootRoute,
  path: '/',
  component: () => <HomePage />,
})

export const applicationRoute = new Route({
  path: 'soknad',
  getParentRoute: () => rootRoute,
  component: () => <Outlet />,
})

const idParam = z.object({
  id: z.string().optional(),
})

const stages = [
  'info',
  'inntekt',
  'arbeidsforhold',
  'sykemelding',
  'foreldrepermisjon',
  'engangsstonad',
  'pleiepenger',
  'oppsummering',
] as const
const applicationStageParam = z.object({
  state: z.enum(stages).optional(),
})

export type ApplicationStage = (typeof stages)[number]

export const applicationInformationRoute = new Route({
  path: '/',
  getParentRoute: () => applicationRoute,
  component: lazyRouteComponent(() => import('./pages/Home.page'), 'HomePage'),
})

export const applicationConcentRoute = new Route({
  path: '/samtykke',
  getParentRoute: () => applicationRoute,
  component: lazyRouteComponent(() => import('./pages/ApplicationConcent.page'), 'ApplicationConcentPage'),
})

export const applicationEligibilityRoute = new Route({
  path: 'har-jeg-rett-til-sykepenger',
  getParentRoute: () => applicationRoute,
  component: lazyRouteComponent(() => import('./features/CheckEligibilityForm'), 'CheckEligibilityForm'),
})

export const manualSickLeaveRoute = new Route({
  path: 'sykepenger',
  validateSearch: idParam.merge(applicationStageParam),
  getParentRoute: () => applicationRoute,
  component: lazyRouteComponent(() => import('./pages/SickLeaveApplication.page'), 'SickLeaveApplicationPage'),
})

export const manualCaregiverRoute = new Route({
  path: 'pleie',
  validateSearch: idParam.merge(applicationStageParam),
  getParentRoute: () => applicationRoute,
  component: lazyRouteComponent(
    () => import('./pages/CaregiverLeaveApplication.page'),
    'CaregiverLeaveApplicationPage'
  ),
})

export const manualParentalRoute = new Route({
  path: 'fodsel-og-adopsjon',
  validateSearch: idParam.merge(applicationStageParam),
  getParentRoute: () => applicationRoute,
  component: lazyRouteComponent(() => import('./pages/ParentalLeaveApplication.page'), 'ParentalLeaveApplicationPage'),
})

export const manualParentalLumpSumGrantRoute = new Route({
  path: 'fodsel-og-adopsjon-engangsstonad',
  validateSearch: idParam.merge(applicationStageParam),
  getParentRoute: () => applicationRoute,
  component: lazyRouteComponent(
    () => import('./pages/ParentalLumpSumGrantApplication.page'),
    'ParentalLumpSumGrantApplicationPage'
  ),
})

export const newApplicationConsentRoute = new Route({
  path: 'valg',
  getParentRoute: () => applicationRoute,
  component: lazyRouteComponent(() => import('./pages/NewApplicationConsent.page'), 'NewApplicationConsentPage'),
})

export const bankIDRedirectURLCallbackRoute = new Route({
  path: 'oauth/callback/bankid',
  getParentRoute: () => rootRoute,
  component: lazyRouteComponent(() => import('./BankIDRedirectHandler'), 'BankIDRedirectHandler'),
})

const routeTree = rootRoute.addChildren([
  homeRoute,
  bankIDRedirectURLCallbackRoute,
  applicationRoute.addChildren([
    applicationInformationRoute,
    applicationConcentRoute,
    manualSickLeaveRoute,
    newApplicationConsentRoute,
    manualCaregiverRoute,
    manualParentalRoute,
    manualParentalLumpSumGrantRoute,
    applicationEligibilityRoute,
  ]),
])

export const router = new Router({ routeTree })

declare module '@tanstack/react-router' {
  // eslint-disable-next-line @typescript-eslint/consistent-type-definitions
  interface Register {
    router: typeof router
  }
}

const delay = (ms: number) => new Promise((res) => setTimeout(res, ms))
