import { defineStyle, defineStyleConfig } from '@chakra-ui/react'

const baseStyle = defineStyle({
  color: 'brand.blue',
  borderRadius: '3px',
  fontWeight: 500,
})

const outline = defineStyle({
  outlineColor: 'brand.blue',
  color: 'brand.blue',
  borderSize: '0px',
  outlineWidth: '1px',
  outlineOffset: '0px',
})

const link = defineStyle({
  fontWeight: 400,
  color: 'brand.blue',
  textDecoration: 'underline',
})

export const buttonTheme = defineStyleConfig({
  baseStyle,
  variants: { outline, link },
})
