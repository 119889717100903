import {
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverProps,
  PopoverTrigger,
  SystemStyleObject,
  chakra,
  useToken,
} from '@chakra-ui/react'
import { Question } from '@phosphor-icons/react'
import { ComponentProps, FC, ReactNode } from 'react'

export const ExtraInformation: FC<
  { information: ReactNode; sx?: SystemStyleObject } & Omit<PopoverProps, 'sx' | 'variant' | 'trigger' | 'children'>
> = ({ information, sx, ...props }) => {
  const color = useToken('colors', 'brand.blue')
  return (
    <Popover {...props} trigger="hover" variant={'extraInformation'}>
      <PopoverTrigger>
        <Q sx={sx} fontSize={'24px'} weight="fill" color={color} />
      </PopoverTrigger>
      <PopoverContent p={2} w={'fit-content'} maxW={'330px'}>
        <PopoverArrow />
        <PopoverBody
          lineHeight={'1.5'}
          p={2}
          fontSize={'md'}
          fontWeight={'normal'}
          whiteSpace={'wrap'}
          textAlign={'left'}
        >
          {information}
        </PopoverBody>
      </PopoverContent>
    </Popover>
  )
}
export type ExtraInformationProps = ComponentProps<typeof ExtraInformation>

const Q = chakra(Question)
