import { checkboxAnatomy } from '@chakra-ui/anatomy'
import { createMultiStyleConfigHelpers } from '@chakra-ui/react'

const helper = createMultiStyleConfigHelpers(checkboxAnatomy.keys)

const baseStyle = helper.definePartsStyle({
  control: {
    borderRadius: 'sm',
    padding: 2,
    backgroundColor: 'white',
    borderColor: 'brand.blue',
    borderWidth: '1px',
    _checked: {
      backgroundColor: 'brand.blue',
      borderColor: 'brand.blue',
    },
  },
})

export const checkboxTheme = helper.defineMultiStyleConfig({ baseStyle })
