import { alertAnatomy } from '@chakra-ui/anatomy'
import { AlertProps, StyleFunctionProps, createMultiStyleConfigHelpers } from '@chakra-ui/react'
import { colors } from './colors'

const helper = createMultiStyleConfigHelpers(alertAnatomy.keys)
const baseStyle = helper.definePartsStyle((args: AlertProps & StyleFunctionProps) => {
  const themeColors = args.theme.colors as ColorObject

  let background = colors.brand.blue
  let color = 'white'
  let iconColor = 'white'

  switch (args.status) {
    case 'success':
      background = colors.brand.blue
      break
    case 'error':
      background = themeColors['red'][400]
      color = themeColors['red'][900]
      iconColor = themeColors['red'][800]
      break
    case 'warning':
      background = themeColors['orange'][400]
      color = themeColors['orange'][50]
      iconColor = themeColors['orange'][800]
      break
  }

  return {
    container: {
      background,
      color,
      '& button': {
        color,
      },
    },
    title: {
      color,
    },
    description: {
      color,
    },
    icon: {
      rounded: 'md',
      color: iconColor,
    },
  }
})
export const alertTheme = helper.defineMultiStyleConfig({
  baseStyle,
})

type ColorObject = {
  [key: string]: string
}
