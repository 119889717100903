import { env } from '@/config/env'
import i18next from 'i18next'
import Locize, { LocizeBackendOptions } from 'i18next-locize-backend'
import { locizePlugin } from 'locize'
import { initReactI18next } from 'react-i18next'
import { z } from 'zod'
import { zodI18nMap } from 'zod-i18n-map'
import zodTranslation from 'zod-i18n-map/locales/nb/zod.json'
const alteredZodTranslation = { ...zodTranslation }
alteredZodTranslation.types.nan = 'ingenting'

const localizeOptions: LocizeBackendOptions = {
  projectId: env.VITE_LOCIZE_PROJECTID,
  apiKey: env.VITE_LOCIZE_API_KEY,
  referenceLng: 'nb',
  version: 'latest',
  private: false,
}
void i18next
  .use(Locize)
  .use(locizePlugin)
  .use(initReactI18next)
  .init({
    react: {
      bindI18n: 'languageChanged editorSaved',
    },
    backend: localizeOptions,
    lng: 'nb', // if you're using a language detector, do not define the lng option
    debug: false,
    fallbackLng: 'nb',
    defaultNS: 'translation',
    fallbackNS: 'translation',
    // if you see an error like: "Argument of type 'DefaultTFuncReturn' is not assignable to parameter of type xyz"
    // set returnNull to false (and also in the i18next.d.ts options)
    // returnNull: false,
  })
z.setErrorMap(zodI18nMap)
i18next.addResourceBundle('nb', 'zod', alteredZodTranslation)

export default i18next
