import { homeRoute } from '@/Router'
import { LogOutButton } from '@/components/buttons'
import { useApplicationContext } from '@/context/ApplicationContext'
import { Container, Divider, HStack, Text } from '@chakra-ui/react'
import { Link } from '@tanstack/react-router'

export const Header = () => {
  const { state } = useApplicationContext()
  const { applicant } = state

  return (
    <Container maxW={'container.xl'}>
      <HStack
        gap={{
          base: 2,
          md: 6,
        }}
        alignItems={{
          base: 'flex-start',
          md: 'center',
        }}
        justifyContent={{
          base: 'flex-start',
          md: 'space-between',
        }}
        py={4}
        flexDirection={{ base: 'column', md: 'row' }}
      >
        <Link to={homeRoute.to} style={{ textTransform: 'uppercase', letterSpacing: '.15rem' }}>
          Sykehjelpsordningen for Tannleger
        </Link>
        <HStack gap={6}>
          {applicant && (
            <>
              <Text fontWeight={500} ml="auto">
                {applicant.givenName} {applicant.surname}
              </Text>
              <LogOutButton />
            </>
          )}
        </HStack>
      </HStack>
      <Divider />
    </Container>
  )
}
