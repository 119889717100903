import { z } from 'zod'
import { CaseStatusEnum, CaseTypeEnum } from '../models/enums'
import { optionalStringOrUndefined } from '../schemaHelpers'
import { ApplicantModel } from './applicant.model'
import { EmploymentTypeEnum } from '../models/enums/EmploymentTypes'
import { LeaveAppliesToEnum } from '../models/enums/LeaveAppliesTo'
import { requiredFile } from '@/zodForm'

export type ApplicationListModel = z.infer<typeof ApplicationListModel>
export const ApplicationListModel = z.object({
  id: z.string(),
  createdOn: z.coerce.date(),
  updatedOn: z.coerce.date(),
  isLocked: z.boolean(),
  status: z.nativeEnum(CaseStatusEnum).nullable(),
  hasUnreadMessages: z.coerce.boolean().default(false),
  caseNumber: z.coerce.string().nullish(),
  totalAllowance: z.number().nullish(),
  type: z.nativeEnum(CaseTypeEnum),
})

export type CreateApplicationModel = z.infer<typeof CreateApplicationModel>
export const CreateApplicationModel = z.object({
  caseType: z.nativeEnum(CaseTypeEnum),
})

export type CreatedApplicationModel = z.infer<typeof CreatedApplicationModel>
export const CreatedApplicationModel = z.object({
  id: z.string(),
})

export type CreateIncomeModel = z.infer<typeof CreateIncomeModel>
export const CreateIncomeModel = z.object({
  grossIncome: z.number().default(0),
  year: z.number(),
  attachment: z.any(),
})

export type UpdateIncomeModel = z.infer<typeof UpdateIncomeModel>
export const UpdateIncomeModel = z
  .object({
    id: z.string(),
  })
  .merge(CreateIncomeModel)

export type AttachmentModel = z.infer<typeof attachment>
export const attachment = z.object({
  id: z.string(),
  name: z.string(),
  mimeType: optionalStringOrUndefined,
})

export const optionalAttachment = attachment.nullish().transform((x) => {
  if (x === null) return undefined
  return x
})

export type IncomeModel = z.infer<typeof IncomeModel>
export const IncomeModel = z.object({
  id: z.string(),
  income: z.number(),
  year: z.number(),
  attachment: optionalAttachment,
})

export type SickLeavePeriodModel = z.infer<typeof SickLeavePeriodModel>
export const SickLeavePeriodModel = z.object({
  id: optionalStringOrUndefined,
  fromDate: z.coerce.date().nullish(),
  toDate: z.coerce.date().nullish(),
  percentage: z.number(),
})

export type DiagnosisModel = z.infer<typeof DiagnosisModel>
export const DiagnosisModel = z.object({
  id: z.string(),
  code: z.string(),
  description: z.string(),
})

export type SickLeaveModel = z.infer<typeof SickLeaveModel>
export const SickLeaveModel = z.object({
  id: z.string(),
  diagnosis: DiagnosisModel.nullable(),
  undefinedDiagnosis: optionalStringOrUndefined,
  relatedIllness: z.boolean(),
  attachment: optionalAttachment,
  order: z.number().default(0),
  periods: z.array(SickLeavePeriodModel),
})

export type CreateSickLeaveModel = z.infer<typeof CreateSickLeaveModel>
export const CreateSickLeaveModel = z.object({
  diagnosisId: optionalStringOrUndefined,
  diagnosis: optionalStringOrUndefined,
  undefinedDiagnosis: optionalStringOrUndefined,
  attachment: z.instanceof(File).nullish(),
  relatedIllness: z.boolean(),
  order: z.number(),
  periods: z.array(SickLeavePeriodModel),
})

export type UpdateSickLeaveModel = z.infer<typeof UpdateSickLeaveModel>
export const UpdateSickLeaveModel = z
  .object({
    id: optionalStringOrUndefined,
  })
  .and(CreateSickLeaveModel)

export type EmploymentModel = z.infer<typeof EmploymentModel>
export const EmploymentModel = z.object({
  id: z.string(),
  employmentType: z.nativeEnum(EmploymentTypeEnum),
  employmentPercentage: z.coerce
    .number()
    .nullish()
    .transform((x) => x ?? 0),
  grossSalary: z.coerce
    .number()
    .nullish()
    .transform((x) => x ?? 0),
  grossSalary2: z.coerce
    .number()
    .nullish()
    .transform((x) => x ?? 0),
  grossSalary3: z.coerce
    .number()
    .nullish()
    .transform((x) => x ?? 0),
  salaryAttachment: optionalAttachment,
  salary2Attachment: optionalAttachment,
  salary3Attachment: optionalAttachment,
  companyName: optionalStringOrUndefined,
  organizationNumber: optionalStringOrUndefined,
  isPrimaryEmployment: z.coerce.boolean(),
  hasFullSalaryFromEmployer: z.coerce.boolean().default(false),
  extraPayslip: z.coerce.boolean(),
})

export type CreateEmploymentModel = z.infer<typeof CreateEmploymentModel>
export const CreateEmploymentModel = z.object({
  employmentType: z.nativeEnum(EmploymentTypeEnum),
  employmentPercentage: z.coerce
    .number()
    .nullish()
    .transform((x) => x ?? 0),
  grossSalary: z.coerce
    .number()
    .nullish()
    .transform((x) => x ?? 0),
  grossSalary2: z.coerce
    .number()
    .nullish()
    .transform((x) => x ?? 0),
  grossSalary3: z.coerce
    .number()
    .nullish()
    .transform((x) => x ?? 0),
  salaryAttachment: z.instanceof(File).optional(),
  salary2Attachment: z.instanceof(File).optional(),
  salary3Attachment: z.instanceof(File).optional(),
  companyName: optionalStringOrUndefined,
  organizationNumber: optionalStringOrUndefined,
  isPrimaryEmployment: z.coerce.boolean(),
  hasFullSalaryFromEmployer: z.coerce.boolean().default(false),
  extraPayslip: z.coerce.boolean(),
})

export type UpdateEmploymentModel = z.infer<typeof UpdateEmploymentModel>
export const UpdateEmploymentModel = z
  .object({
    id: z.string(),
  })
  .and(CreateEmploymentModel)

export type UpdateCaringAllowanceModel = z.infer<typeof UpdateCaringAllowanceModel>
export const UpdateCaringAllowanceModel = z.object({
  id: optionalStringOrUndefined,
  fromDate: z.coerce.date().nullish(),
  toDate: z.coerce.date().nullish(),
  attachment: z.instanceof(File).optional(),
  // leaveDays: z.number().optional(),
  appliesTo: z.nativeEnum(LeaveAppliesToEnum),
})

export type CaringAllowanceModel = z.infer<typeof CaringAllowanceModel>
export const CaringAllowanceModel = z.object({
  id: z.string(),
  fromDate: z.coerce.date().nullish(),
  toDate: z.coerce.date().nullish(),
  attachment: optionalAttachment,
  // leaveDays: z.number().optional(),
  appliesTo: z.nativeEnum(LeaveAppliesToEnum),
})

export type UpdateBirthAndAdoptionAllowanceModel = z.infer<typeof UpdateBirthAndAdoptionAllowanceModel>
export const UpdateBirthAndAdoptionAllowanceModel = z.object({
  id: optionalStringOrUndefined,
  fromDate: z.coerce.date().nullish(),
  toDate: z.coerce.date().nullish(),
  attachment: z.instanceof(File).optional(),
  // leaveDays: z.number().optional(),
})

export type BirthAndAdoptionAllowanceModel = z.infer<typeof BirthAndAdoptionAllowanceModel>
export const BirthAndAdoptionAllowanceModel = z.object({
  id: z.string(),
  fromDate: z.coerce.date().nullish(),
  toDate: z.coerce.date().nullish(),
  attachment: optionalAttachment,
  // leaveDays: z.number().optional(),
})

export type UpdateBirthAndAdoptionLumpSumGrantAllowanceModel = z.infer<
  typeof UpdateBirthAndAdoptionLumpSumGrantAllowanceModel
>
export const UpdateBirthAndAdoptionLumpSumGrantAllowanceModel = z.object({
  id: optionalStringOrUndefined,
  resolutionDate: z.coerce.date().nullish(),
  attachment: z.instanceof(File).optional(),
  // leaveDays: z.number().optional(),
})

export type BirthAndAdoptionLumpSumGrantAllowanceModel = z.infer<typeof BirthAndAdoptionLumpSumGrantAllowanceModel>
export const BirthAndAdoptionLumpSumGrantAllowanceModel = z.object({
  id: z.string(),
  resolutionDate: z.coerce.date().nullish(),
  attachment: optionalAttachment,
})

export type ApplicationModel = z.infer<typeof ApplicationModel>
export const ApplicationModel = z.object({
  id: z.string(),
  isSubmitted: z.boolean(),
  caseHandler: optionalStringOrUndefined,
  caseCreatedOn: z.coerce.date().nullable(),
  status: z.nativeEnum(CaseStatusEnum).nullable(),
  applicant: ApplicantModel,
  caseType: z.nativeEnum(CaseTypeEnum),
  useLastYearsIncome: z.boolean(),
  hasDisabilityPension: z.boolean(),
  grossDisabilityPension: z.number().optional(),
  incomes: z.array(IncomeModel),
  employments: z.array(EmploymentModel),
  sickLeaves: z.array(SickLeaveModel).nullish(),
  comment: optionalStringOrUndefined,
  caringAllowance: CaringAllowanceModel.nullable(),
  birthAndAdoptionAllowance: BirthAndAdoptionAllowanceModel.nullable(),
  birthAndAdoptionOneTimeAllowance: BirthAndAdoptionLumpSumGrantAllowanceModel.nullish(),
  disabilityPensionAttachment: optionalAttachment,

  powerOfAttorneyDocument: optionalAttachment,
})

export type UpdateApplicationModel = z.infer<typeof UpdateApplicationModel>
export const UpdateApplicationModel = z.object({
  id: z.string(),
  useLastYearsIncome: z.boolean(),
  hasDisabilityPension: z.boolean(),
  grossDisabilityPension: z.coerce.number().optional(),
  comment: optionalStringOrUndefined,
  disabilityPensionAttachment: z.instanceof(File).optional(),

  powerOfAttorneyRequired: z.boolean().default(false),
  powerOfAttorneyDocument: requiredFile.optional(),
  powerOfAttorneyDocumentId: optionalStringOrUndefined,
})

export type ApplicationMessageModel = z.infer<typeof ApplicationMessageModel>
export const ApplicationMessageModel = z.object({
  id: z.string(),
  caseId: z.string(),
  createdOn: z.coerce.date(),
  isReadByApplicant: z.boolean(),
  message: z.string(),
  isFromApplicant: z.coerce.boolean(),
  writtenBy: optionalStringOrUndefined,
})

export type ApplicationCalculationsModel = z.infer<typeof ApplicationCalculationsModel>
export const ApplicationCalculationsModel = z.object({
  total: z.number().nullish().default(0),
  payouts: z.array(
    z.object({
      month: z.number(),
      year: z.number(),
      total: z.number(),
      dailyAllowance: z.number(),
      allowanceDays: z.number(),
      isSentForPayout: z.boolean().default(false),
    })
  ),
})
