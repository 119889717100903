import { Box, Button, Grid, HStack, Heading, Text, TextProps, VStack, useToken } from '@chakra-ui/react'
import { Link } from '@tanstack/react-router'
import {
  manualCaregiverRoute,
  manualParentalRoute,
  manualParentalLumpSumGrantRoute,
  manualSickLeaveRoute,
} from '../../Router'
import { useApplicationContext } from '@/context/ApplicationContext'
import { getStatusText, toDateString } from '@/lib/utility'
import { CaseStatusEnum, CaseType, CaseTypeEnum } from '@/lib/models/enums'
import { FC, ReactNode, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { ApplicationMessage } from '../ApplicationMessage'
import { Lock } from '@phosphor-icons/react'
import { ApplicationListModel } from '@/lib/api/application.model'
import { ApplicationCalculations } from '../ApplicationCalculations'

const getTypeRoutePath = (type: CaseTypeEnum) => {
  switch (type) {
    case CaseTypeEnum.SickLeave:
      return manualSickLeaveRoute.fullPath
    case CaseTypeEnum.Parental:
      return manualParentalRoute.fullPath
    case CaseTypeEnum.LumpSumGrant:
      return manualParentalLumpSumGrantRoute.fullPath
    case CaseTypeEnum.Care:
      return manualCaregiverRoute.fullPath
    default:
      return ''
  }
}

type Props = {
  emptyText?: string
}

export const MyApplications: FC<Props> = ({ emptyText }) => {
  const { t } = useTranslation()
  const {
    state: { applications },
  } = useApplicationContext()

  const preparedApplications = applications.sort((a, b) => {
    return a.createdOn > b.createdOn ? -1 : 1
  })

  const brandToken = useToken('colors', 'brand.blue')

  const activeCases = preparedApplications.filter(
    (application) => application.status !== CaseStatusEnum.Avsluttet && application.status !== CaseStatusEnum.Avslag
  )
  const closedCases = preparedApplications.filter((x) => x.status === CaseStatusEnum.Avsluttet)
  const rejectedCases = preparedApplications.filter((x) => x.status === CaseStatusEnum.Avslag)

  const [currentTab, setCurrentTab] = useState<'active' | 'closed' | 'rejected'>('active')

  let toRender: ApplicationListModel[] = []
  switch (currentTab) {
    case 'active':
      toRender = activeCases
      break
    case 'closed':
      toRender = closedCases
      break
    case 'rejected':
      toRender = rejectedCases
      break
    default:
      break
  }

  return (
    <Box w={'full'} mt={6}>
      <HStack
        justifyContent={'space-between'}
        alignItems={'flex-start'}
        flexDirection={{
          base: 'column',
          md: 'row',
        }}
      >
        <Heading variant={'widgetSmall'}>{t('newApplicationPage.previousApplications.title')}</Heading>
        <HStack gap={4}>
          <CasesOfType
            isActive={currentTab === 'active'}
            label={t('myApplications.filter.active')}
            cases={activeCases.length}
            onClick={() => setCurrentTab('active')}
          />
          <CasesOfType
            isActive={currentTab === 'closed'}
            label={t('myApplications.filter.completed')}
            cases={closedCases.length}
            onClick={() => setCurrentTab('closed')}
          />
          <CasesOfType
            isActive={currentTab === 'rejected'}
            label={t('myApplications.filter.rejected')}
            cases={rejectedCases.length}
            onClick={() => setCurrentTab('rejected')}
          />
        </HStack>
      </HStack>
      {(toRender.length === 0 && (
        <Text mt={2} fontSize={'1.25rem'}>
          {emptyText}
        </Text>
      )) || (
        <VStack mt={2} spacing={4} w={'full'}>
          {toRender.map((application) => (
            <Link
              key={application.id}
              to={getTypeRoutePath(application.type) + '?id=' + application.id}
              style={{
                width: '100%',
              }}
            >
              <Grid
                w={'full'}
                gridTemplateColumns={{
                  base: '1fr',
                  md: 'repeat(3,1fr)',
                  lg: 'repeat(6,1fr)',
                }}
                px={8}
                pt={4}
                pb={3}
                gap={{
                  base: 4,
                  lg: 0,
                }}
                backgroundColor={'brand.lightGray'}
                rounded={2}
              >
                <VStack alignItems={'flex-start'} justifyContent={'space-between'} height={'full'}>
                  <ListHeading>{t('myApplications.status')}</ListHeading>
                  <ListContent height={'30px'}>
                    {getStatusText(application)}
                    {application.isLocked && <Lock opacity={0.5} size={16} color={brandToken} />}
                  </ListContent>
                </VStack>
                <VStack alignItems={'flex-start'} justifyContent={'space-between'} height={'full'}>
                  <ListHeading>{t('myApplications.type')}</ListHeading>
                  <ListContent height={'30px'}>
                    {t(CaseType[application.type] as 'selectApplicationType.sickLeave')}
                  </ListContent>
                </VStack>
                <VStack alignItems={'flex-start'} justifyContent={'space-between'} height={'full'}>
                  <ListHeading>{t('myApplications.allowance')}</ListHeading>
                  <ApplicationCalculations
                    applicationId={application.id}
                    total={application.totalAllowance ?? undefined}
                  />
                </VStack>
                <VStack alignItems={'flex-start'} justifyContent={'space-between'} height={'full'}>
                  <ListHeading>{t('myApplications.sendt')}</ListHeading>
                  {(application.isLocked && (
                    <>
                      <ListContent height={'30px'}>{toDateString(application.createdOn)}</ListContent>
                    </>
                  )) || <ListContent height={'30px'}>-</ListContent>}
                </VStack>
                <VStack alignItems={'flex-start'} justifyContent={'space-between'} height={'full'}>
                  <ListHeading>{t('myApplications.case')}</ListHeading>
                  <ListContent height={'30px'}>
                    {application.caseNumber ? `#${application.caseNumber}` : '-'}
                  </ListContent>
                </VStack>
                <VStack
                  alignItems={{
                    base: 'flex-start',
                  }}
                  justifyContent={'center'}
                  height={'full'}
                >
                  <ListHeading>{t('myApplications.messages')}</ListHeading>
                  {(application.caseNumber && (
                    <ApplicationMessage
                      hasUnreadMessages={application.hasUnreadMessages}
                      createdOn={application.createdOn}
                      caseType={application.type}
                      caseNumber={application.caseNumber || '-'}
                      status={application.status || CaseStatusEnum.Underbehandling}
                      applicationId={application.id}
                    />
                  )) || (
                    <ListContent
                      height={'30px'}
                      ml={{
                        base: '0',
                        lg: 8,
                      }}
                    >
                      -
                    </ListContent>
                  )}
                </VStack>
              </Grid>
            </Link>
          ))}
        </VStack>
      )}
    </Box>
  )
}

type CasesOfTypeProps = {
  label: string
  cases: number
  isActive?: boolean
  onClick?: () => void
}
const CasesOfType: FC<CasesOfTypeProps> = ({ label, cases, isActive, onClick }) => {
  return (
    <Button variant={'link'} size={'md'} textDecoration={isActive ? 'underline' : 'none'} onClick={onClick}>
      <Text>{label}</Text> <Text>({cases})</Text>
    </Button>
  )
}

export const ListHeading = ({ children }: { children?: ReactNode }) => (
  <Text fontWeight={'300'} fontSize={'1rem'}>
    {children}
  </Text>
)
export const ListContent = ({ children, ...rest }: { children?: ReactNode } & TextProps) => (
  <Text fontWeight={'500'} fontSize={'1rem'} display={'flex'} gap={2} alignItems={'center'} {...rest}>
    {children}
  </Text>
)
