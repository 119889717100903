import { defineStyle, defineStyleConfig } from '@chakra-ui/react'

// define the base component styles
const baseStyle = defineStyle({
  background: 'brand.blue',
  borderRadius: 'sm',
  color: 'white',
  fontWeight: 300,
  lineHeight: '164%',
  '--popper-arrow-bg': 'var(--chakra-colors-brand-blue)',
})

const extraInformation = defineStyle({
  padding: '1.25rem',
})

// export the component theme
export const tooltipTheme = defineStyleConfig({ baseStyle, variants: { extraInformation } })
