import { inputAnatomy } from '@chakra-ui/anatomy'
import { createMultiStyleConfigHelpers } from '@chakra-ui/react'

const helper = createMultiStyleConfigHelpers(inputAnatomy.keys)

const outline = helper.definePartsStyle({
  field: {
    border: '1px solid',
    borderColor: 'brand.blue',
    background: 'white',
    borderRadius: 'sm',
    marginBottom: 0,
    "&[aria-invalid='true']": {
      borderColor: '#ffa200',
      boxShadow: '0 0 0 0px #ffa200',
    },
  },
  addon: {
    border: '1px solid',
    borderColor: 'brand.blue',
    background: 'white',
    borderRadius: 'sm',
    color: 'gray.500',
  },
})

export const inputTheme = helper.defineMultiStyleConfig({
  variants: { outline },
})
