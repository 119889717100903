import ky from 'ky'
import { createQuery } from 'react-query-kit'
import { z } from 'zod'
import { env } from './config/env'

export const useMe = createQuery({
  queryKey: ['me'],
  fetcher: async () => MeSchema.parse(await ky.get(`${env.VITE_API_URL}/users/me`, { credentials: 'include' }).json()),
  retry: 1,
  refetchInterval: 1000 * 60,
})
const MeSchema = z.object({
  nnin: z.string().describe('Norwegian National Identity Number'),
  givenName: z.string(),
  familyName: z.string(),
  birthdate: z.string(),
  id: z.string(),
})
