export const assignIfDefined = (form: FormData, key: string, value: unknown) => {
  if (value == null || value === undefined) return undefined

  if (typeof value === 'boolean' || typeof value === 'number') {
    form.append(key, `${value}`)
  }

  if (typeof value === 'string' && value.length > 0) {
    form.append(key, value)
  }

  if (value instanceof Date) {
    form.append(key, value.toISOString())
  }

  if (value instanceof File && value.size > 1) {
    form.append(key, value)
  }

  if (typeof value === 'object') {
    form.append(key, JSON.stringify(value))
  }
}
