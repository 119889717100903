/* eslint-disable max-lines */
import { tannlegeforeningenApi } from './client'
import {
  ApplicationCalculationsModel,
  ApplicationMessageModel,
  ApplicationModel,
  BirthAndAdoptionAllowanceModel,
  BirthAndAdoptionLumpSumGrantAllowanceModel,
  CaringAllowanceModel,
  CreateApplicationModel,
  CreateEmploymentModel,
  CreateIncomeModel,
  CreateSickLeaveModel,
  CreatedApplicationModel,
  EmploymentModel,
  IncomeModel,
  SickLeaveModel,
  UpdateApplicationModel,
  UpdateBirthAndAdoptionAllowanceModel,
  UpdateBirthAndAdoptionLumpSumGrantAllowanceModel,
  UpdateCaringAllowanceModel,
  UpdateEmploymentModel,
  UpdateIncomeModel,
  UpdateSickLeaveModel,
} from './application.model'
import { UpdateApplicantModel } from './applicant.model'
import { assignIfDefined } from './formHelper'
import { z } from 'zod'
import { removeMoneyFormat } from '../utility'

const base = `applications`
const route = (id: string, extra?: string) => `${base}/${id}${extra ? `/${extra}` : ''}`

export const getApplicationCalculations = async (applicationId: string) => {
  const req = await tannlegeforeningenApi.get(route(applicationId, 'calculations')).json()
  return ApplicationCalculationsModel.parse(req)
}

export const getApplication = async (applicationId: string) => {
  const req = await tannlegeforeningenApi.get(route(applicationId)).json()
  return ApplicationModel.parse(req)
}

type UpdateApplicantPayload = {
  body: UpdateApplicantModel
  applicationId: string
}
export const updateApplicationApplicant = async ({ body, applicationId }: UpdateApplicantPayload) => {
  await tannlegeforeningenApi.patch(route(applicationId, `applicant`), {
    json: UpdateApplicantModel.parse(body),
  })
}

type CreateApplicationPayload = {
  body: CreateApplicationModel
}
export const createApplication = async ({ body }: CreateApplicationPayload) => {
  const req = await tannlegeforeningenApi.post(base, { json: CreateApplicationModel.parse(body) }).json()
  return CreatedApplicationModel.parse(req)
}

export const deleteApplication = async ({ applicationId }: { applicationId: string }) => {
  await tannlegeforeningenApi.delete(route(applicationId))
}

type UpdateApplicationPayload = {
  applicationId: string
  body: UpdateApplicationModel
}
export const updateApplication = async ({ body, applicationId }: UpdateApplicationPayload) => {
  const form = new FormData()
  assignIfDefined(form, 'id', body.id)
  assignIfDefined(form, 'comment', body.comment)
  assignIfDefined(form, 'useLastYearsIncome', body.useLastYearsIncome)
  assignIfDefined(form, 'hasDisabilityPension', body.hasDisabilityPension)
  assignIfDefined(form, 'grossDisabilityPension', removeMoneyFormat(body.grossDisabilityPension))
  assignIfDefined(form, 'disabilityPensionAttachment', body.disabilityPensionAttachment)
  assignIfDefined(form, 'powerOfAttorneyDocument', body.powerOfAttorneyDocument)

  await tannlegeforeningenApi.patch(route(applicationId), { body: form }).json()
}

// EMPLOYMENT
type CreateEmploymentPayload = {
  body: CreateEmploymentModel
  applicationId: string
}
export const createEmployment = async ({ body, applicationId }: CreateEmploymentPayload) => {
  const form = new FormData()
  assignIfDefined(form, 'grossSalary', removeMoneyFormat(body.grossSalary))
  assignIfDefined(form, 'grossSalary2', removeMoneyFormat(body.grossSalary2))
  assignIfDefined(form, 'grossSalary3', removeMoneyFormat(body.grossSalary3))

  assignIfDefined(form, 'salaryAttachment', body.salaryAttachment)
  assignIfDefined(form, 'salary2Attachment', body.salary2Attachment)
  assignIfDefined(form, 'salary3Attachment', body.salary3Attachment)
  assignIfDefined(form, 'hasFullSalaryFromEmployer', body.hasFullSalaryFromEmployer)

  assignIfDefined(form, 'employmentType', body.employmentType)
  // assignIfDefined(form, 'organizationNumber', body.organizationNumber)
  assignIfDefined(form, 'companyName', body.companyName)
  assignIfDefined(form, 'employmentPercentage', body.employmentPercentage)
  assignIfDefined(form, 'extraPayslip', body.extraPayslip)
  assignIfDefined(form, 'isPrimaryEmployment', body.isPrimaryEmployment)

  const req = await tannlegeforeningenApi
    .post(route(applicationId, `employments`), {
      body: form,
    })
    .json()
  return EmploymentModel.parse(req)
}

type UpdateEmploymentPayload = {
  body: UpdateEmploymentModel
  applicationId: string
  employmentId: string
}
export const updateEmployment = async ({ body, applicationId, employmentId }: UpdateEmploymentPayload) => {
  const form = new FormData()
  assignIfDefined(form, 'id', employmentId)

  assignIfDefined(form, 'grossSalary', removeMoneyFormat(body.grossSalary))
  assignIfDefined(form, 'grossSalary2', removeMoneyFormat(body.grossSalary2))
  assignIfDefined(form, 'grossSalary3', removeMoneyFormat(body.grossSalary3))
  assignIfDefined(form, 'salaryAttachment', body.salaryAttachment)
  assignIfDefined(form, 'salary2Attachment', body.salary2Attachment)
  assignIfDefined(form, 'salary3Attachment', body.salary3Attachment)
  assignIfDefined(form, 'hasFullSalaryFromEmployer', body.hasFullSalaryFromEmployer)

  assignIfDefined(form, 'employmentType', body.employmentType)
  // assignIfDefined(form, 'organizationNumber', body.organizationNumber)
  assignIfDefined(form, 'companyName', body.companyName)
  assignIfDefined(form, 'employmentPercentage', body.employmentPercentage)
  assignIfDefined(form, 'extraPayslip', body.extraPayslip)
  assignIfDefined(form, 'isPrimaryEmployment', body.isPrimaryEmployment)

  const req = await tannlegeforeningenApi
    .patch(route(applicationId, `employments/${employmentId}`), {
      body: form,
    })
    .json()
  return EmploymentModel.parse(req)
}

type DeleteEmploymentPayload = {
  applicationId: string
  employmentId: string
}
export const deleteEmployment = async ({ applicationId, employmentId }: DeleteEmploymentPayload) => {
  await tannlegeforeningenApi.delete(route(applicationId, `employments/${employmentId}`))
}

// INCOME
type CreateIncomePayload = {
  body: CreateIncomeModel
  applicationId: string
}
export const createIncome = async ({ body, applicationId }: CreateIncomePayload) => {
  const form = new FormData()

  assignIfDefined(form, 'year', body.year)
  assignIfDefined(form, 'grossIncome', removeMoneyFormat(body.grossIncome))
  assignIfDefined(form, 'attachment', body.attachment)

  const req = await tannlegeforeningenApi
    .post(route(applicationId, `incomes`), {
      body: form,
    })
    .json()
  return IncomeModel.parse(req)
}

type UpdateIncomePayload = {
  body: UpdateIncomeModel
  applicationId: string
  incomeId: string
}
export const updateIncome = async ({ body, applicationId, incomeId }: UpdateIncomePayload) => {
  const form = new FormData()
  if (body.id) {
    form.append('id', body.id)
  }
  form.append('year', `${body.year}`)
  assignIfDefined(form, 'grossIncome', removeMoneyFormat(body.grossIncome))
  assignIfDefined(form, 'attachment', body.attachment)
  const req = await tannlegeforeningenApi
    .patch(route(applicationId, `incomes/${incomeId}`), {
      body: form,
    })
    .json()
  return IncomeModel.parse(req)
}

type DeleteIncomePayload = {
  applicationId: string
  incomeId: string
}
export const deleteIncome = async ({ incomeId, applicationId }: DeleteIncomePayload) => {
  await tannlegeforeningenApi.delete(route(applicationId, `incomes/${incomeId}`), {})
}

// SICK LEAVE
type CreateSickLeavePayload = {
  body: CreateSickLeaveModel
  applicationId: string
}
export const createSickLeave = async ({ body, applicationId }: CreateSickLeavePayload) => {
  const form = new FormData()

  assignIfDefined(form, 'relatedIllness', body.relatedIllness)
  assignIfDefined(form, 'undefinedDiagnosis', body.undefinedDiagnosis)
  assignIfDefined(form, 'diagnosis', body.diagnosis)
  assignIfDefined(form, 'periods', body.periods)
  assignIfDefined(form, 'attachment', body.attachment)
  assignIfDefined(form, 'order', body.order)

  const req = await tannlegeforeningenApi
    .post(route(applicationId, `sick-leaves`), {
      body: form,
    })
    .json()
  return SickLeaveModel.parse(req)
}

type UpdateSickLeavePayload = {
  body: UpdateSickLeaveModel
  applicationId: string
  sickLeaveId: string
}
export const updateSickLeave = async ({ body, applicationId, sickLeaveId }: UpdateSickLeavePayload) => {
  const form = new FormData()
  if (body.id) {
    form.append('id', body.id)
  }
  assignIfDefined(form, 'relatedIllness', body.relatedIllness)
  assignIfDefined(form, 'undefinedDiagnosis', body.undefinedDiagnosis)
  assignIfDefined(form, 'diagnosis', body.diagnosis)
  assignIfDefined(form, 'periods', body.periods)
  assignIfDefined(form, 'attachment', body.attachment)
  const req = await tannlegeforeningenApi
    .patch(route(applicationId, `sick-leaves/${sickLeaveId}`), {
      body: form,
    })
    .json()
  return SickLeaveModel.parse(req)
}

type DeleteSickLeavePayload = {
  applicationId: string
  sickLeaveId: string
}
export const deleteSickLeave = async ({ sickLeaveId, applicationId }: DeleteSickLeavePayload) => {
  await tannlegeforeningenApi.delete(route(applicationId, `sick-leaves/${sickLeaveId}`), {})
}

type DeleteSickLeavePeriodPayload = {
  applicationId: string
  sickLeaveId: string
  periodId: string
}
export const deleteSickLeavePeriod = async ({ sickLeaveId, applicationId, periodId }: DeleteSickLeavePeriodPayload) => {
  await tannlegeforeningenApi.delete(route(applicationId, `sick-leaves/${sickLeaveId}/periods/${periodId}`), {})
}

// CARE
type CreateCarePayload = {
  body: UpdateCaringAllowanceModel
  applicationId: string
}
export const createCare = async ({ body, applicationId }: CreateCarePayload) => {
  const form = new FormData()
  assignIfDefined(form, 'attachment', body.attachment)
  assignIfDefined(form, 'toDate', body.toDate)
  assignIfDefined(form, 'fromDate', body.fromDate)
  assignIfDefined(form, 'appliesTo', body.appliesTo)
  // assignIfDefined(form, 'leaveDays', body.leaveDays)

  const req = await tannlegeforeningenApi
    .post(route(applicationId, `caring-allowances`), {
      body: form,
    })
    .json()
  return CaringAllowanceModel.parse(req)
}

type UpdateCarePayload = {
  body: UpdateCaringAllowanceModel
  applicationId: string
}
export const updateCare = async ({ body, applicationId }: UpdateCarePayload) => {
  const form = new FormData()
  assignIfDefined(form, 'id', body.id)
  assignIfDefined(form, 'toDate', body.toDate)
  assignIfDefined(form, 'fromDate', body.fromDate)
  assignIfDefined(form, 'appliesTo', body.appliesTo)
  // assignIfDefined(form, 'leaveDays', body.leaveDays)
  assignIfDefined(form, 'attachment', body.attachment)

  const req = await tannlegeforeningenApi
    .patch(route(applicationId, `caring-allowances/${body.id}`), {
      body: form,
    })
    .json()
  return CaringAllowanceModel.parse(req)
}

// BIRTH AND ADOPTION
type CreateBirthAndAdoptionPayload = {
  body: UpdateBirthAndAdoptionAllowanceModel
  applicationId: string
}
export const createBirthAndAdoption = async ({ body, applicationId }: CreateBirthAndAdoptionPayload) => {
  const form = new FormData()
  assignIfDefined(form, 'toDate', body.toDate)
  assignIfDefined(form, 'fromDate', body.fromDate)
  // assignIfDefined(form, 'leaveDays', body.leaveDays)
  assignIfDefined(form, 'attachment', body.attachment)

  const req = await tannlegeforeningenApi
    .post(route(applicationId, `birth-and-adoptions`), {
      body: form,
    })
    .json()
  return BirthAndAdoptionAllowanceModel.parse(req)
}

type UpdateBirthAndAdoptionPayload = {
  body: UpdateBirthAndAdoptionAllowanceModel
  applicationId: string
}
export const updateBirthAndAdoption = async ({ body, applicationId }: UpdateBirthAndAdoptionPayload) => {
  const form = new FormData()
  assignIfDefined(form, 'id', body.id)
  assignIfDefined(form, 'toDate', body.toDate)
  assignIfDefined(form, 'fromDate', body.fromDate)
  // assignIfDefined(form, 'leaveDays', body.leaveDays)
  assignIfDefined(form, 'attachment', body.attachment)

  const req = await tannlegeforeningenApi
    .patch(route(applicationId, `birth-and-adoptions/${body.id}`), {
      body: form,
    })
    .json()

  return BirthAndAdoptionAllowanceModel.parse(req)
}

// BIRTH AND ADOPTION LUMP SUM GRANT
type CreateBirthAndAdoptionLumpSumGrantPayload = {
  body: UpdateBirthAndAdoptionLumpSumGrantAllowanceModel
  applicationId: string
}
export const createBirthAndAdoptionLumpSumGrant = async ({
  body,
  applicationId,
}: CreateBirthAndAdoptionLumpSumGrantPayload) => {
  const form = new FormData()
  assignIfDefined(form, 'resolutionDate', body.resolutionDate)
  assignIfDefined(form, 'attachment', body.attachment)

  const req = await tannlegeforeningenApi
    .post(route(applicationId, `birth-and-adoption-one-time-allowances`), {
      body: form,
    })
    .json()
  return BirthAndAdoptionLumpSumGrantAllowanceModel.parse(req)
}

type UpdateBirthAndAdoptionLumpSumGrantPayload = {
  body: UpdateBirthAndAdoptionLumpSumGrantAllowanceModel
  applicationId: string
}
export const updateBirthAndAdoptionLumpSumGrant = async ({
  body,
  applicationId,
}: UpdateBirthAndAdoptionLumpSumGrantPayload) => {
  const form = new FormData()
  assignIfDefined(form, 'id', body.id)
  assignIfDefined(form, 'resolutionDate', body.resolutionDate)
  assignIfDefined(form, 'attachment', body.attachment)

  const req = await tannlegeforeningenApi
    .patch(route(applicationId, `birth-and-adoption-one-time-allowances/${body.id}`), {
      body: form,
    })
    .json()

  return BirthAndAdoptionLumpSumGrantAllowanceModel.parse(req)
}

export const sendInApplication = async (applicationId: string) => {
  await tannlegeforeningenApi.post(route(applicationId))
}

export const getApplicationMessages = async (applicationId: string) => {
  const req = await tannlegeforeningenApi.get(route(applicationId, 'messages')).json()
  return z.array(ApplicationMessageModel).parse(req)
}

export const markMessagesAsRead = async (applicationId: string) => {
  await tannlegeforeningenApi.post(route(applicationId, 'messages/read')).json()
}

export type PostMessagePayload = {
  applicationId: string
  message: string
}
export const sendMessage = async ({ applicationId, message }: PostMessagePayload) => {
  await tannlegeforeningenApi.post(route(applicationId, 'messages'), {
    json: { message },
  })
}
