import { createActorContext } from '@xstate/react'
import { createMachine } from 'xstate'
import { ApplicantModel } from './lib/api/applicant.model'

export const sykehjelpsOrdningenMachine = createMachine({
  /*  cspell: disable-next-line  */
  /** @xstate-layout N4IgpgJg5mDOIC5SwJ4GswAsBWYA2ADrAPIBOEAdgJYUwUB0sALgIalPEBmAggQXlQDGLJlQD2FAMR4xUGgHUqTTACEWFNAEkIAbQAMAXUSgCY2EvEVjIAB6IAbAHZ6ADkcAWewEYArI4CcLj5eIe4ANCAoiADMwfR6-gBMLgn2ye5JiQC+WRGoGDj4RGSUNHT0MEwAwhKwYBRMAAosMJKVNRR1DfpGSCCm5qIS1nYIiT4u9P560fZ6XvY+c9Eu9hFRCI5LU9GOvnP+c-Yu2bkg+Vi4hCTk1LT1FWDVtfVNLWBtYgAqYgCy6gBXFh4Xj8IQiSw9awDCzDPqjTz0byJDLuNw+aZo9zhSKITKuCbHWLRXbuRynPLoS5FG6le4MSqiWgdLpMSRgUikMSkYgUDqCV5QvowoZWeGIdy7eiJAIhEmJaYK+xrXEIA5IxIhRLeHzJezuCnnKmFa4lO7lRllFmvT7PCgC7qGaFmWFi0AIpxTclJXx7RKanEbFxeaJe6Iheb2WZ6II5SkFK7FW5lB6CCQOpgAUU53MkTFIKG4UBYNCFJhdopGDkCSODMqcjhcJIC60Qjj0PnofvJmpJfh8PhyZwoYggcGsFxNSbpdGdg0sVYQAFoVUH7K4ydE0Y5FuMFo440aEzSzSmGMw2BweHwBMJK8KKwvxQgDa2EF4PPRdYlYj4DSG3F8Q9J0TWlzQedoXgaZoYDnV1F3Jehtx8PRJR3LdVh8N8tnXEMEj0fUYwmH9gONUDT3pR4mCZKBrQaOD73dCV3D0Ls-BReZdRWPQf2wlCu2ieZsQNcYUkHM4QJPZNKMETAxDMMBQVvCEJC+FACDABinyYtVsSmNIuP8LiWK8N98Q7FESTcZIeMNSTTWk8o03tV5sy5UgtLhHTjkSVxki8VEvBcTx-DfOZJmDbd9X8LwYv8dwhyyIA */
  types: {} as {
    context: { concent: boolean | undefined; applicant: ApplicantModel | undefined }
    events: {
      type: 'getConsent' | 'gotConcent' | 'errorOnConcent' | 'tryAgain' | 'loginWithBankId' | 'goToManualApplication'
    }

    state:
      | { type: 'startOfApplication' }
      | { type: 'loggingIn' }
      | { type: 'getConsentPage' }
      | { type: 'gettingConsent' }
      | { type: 'loginFailed' }
      | { type: 'chooseApplicationType' }
      | { type: 'concentError' }
  },
  context: { concent: undefined, applicant: undefined },

  states: {
    startOfApplication: {
      on: {
        loginWithBankId: 'getConsentPage',
      },
    },

    getConsentPage: {
      on: {
        getConsent: 'gettingConsent',
        goToManualApplication: 'chooseApplicationType',
      },
    },

    gettingConsent: {
      on: {
        errorOnConcent: 'concentError',
        gotConcent: {
          target: 'chooseApplicationType',
          actions: 'setConcent',
        },
      },
    },

    chooseApplicationType: {},

    concentError: {
      on: {
        tryAgain: 'gettingConsent',
      },
    },
  },

  id: 'sykehjelpsOrdningen',
  initial: 'startOfApplication',
})

export const SykehjelpsOrdningenMachineContext = createActorContext(sykehjelpsOrdningenMachine)
