import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  ModalProps,
  ModalBodyProps,
  ModalContentProps,
  useDisclosure,
  ModalFooter,
} from '@chakra-ui/react'
import { FC, ReactNode, useState } from 'react'

type Props = {
  title: ReactNode
  body: ReactNode
  footer?: ReactNode
  showCloseButton?: boolean
  modalProps?: Omit<ModalProps, 'children' | 'onOpen' | 'onClose' | 'isOpen'>
  modalContentProps?: ModalContentProps
  modalBodyProps?: ModalBodyProps
  controller: (onOpen: () => void, onClose: () => void) => ReactNode
}
export const BaseModal: FC<Props> = ({
  modalProps,
  modalBodyProps,
  modalContentProps,
  showCloseButton = true,
  controller,
  title,
  body,
  footer,
}) => {
  const { onOpen, onClose, isOpen } = useDisclosure()
  const [unmounted, setUnmounted] = useState(false)

  const doClose = () => {
    onClose()
    setTimeout(() => {
      setUnmounted(true)
    }, 200)
  }

  const doOpen = () => {
    setUnmounted(false)
    onOpen()
  }

  return (
    <>
      {controller(doOpen, doClose)}
      {!unmounted && (
        <Modal isCentered size={'sm'} isOpen={isOpen} onClose={doClose} {...modalProps}>
          <ModalOverlay />
          <ModalContent height={'fit-content'} minHeight={'fit-content'} {...modalContentProps}>
            <ModalHeader fontSize={'2xl'}>{title}</ModalHeader>
            {showCloseButton && <ModalCloseButton />}
            <ModalBody height={'fit-content'} pb={7} {...modalBodyProps}>
              {body}
            </ModalBody>
            {footer && <ModalFooter>{footer}</ModalFooter>}
          </ModalContent>
        </Modal>
      )}
    </>
  )
}
