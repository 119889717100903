import { BaseModal } from '@/components/modals/BaseModal'
import { useGetApplicationCalculations } from '@/lib/api/application.hook'
import { ApplicationCalculationsModel } from '@/lib/api/application.model'
import { ToNoK, monthYearOnly } from '@/lib/utility'
import { Button, Center, Divider, Grid, HStack, Spinner, Text, useToken } from '@chakra-ui/react'
import { ArrowSquareOut, Check } from '@phosphor-icons/react'
import { FC, Fragment } from 'react'
import { useTranslation } from 'react-i18next'

type ApplicationCalculationsProps = {
  applicationId: string
  total?: number
}

export const ApplicationCalculations: FC<ApplicationCalculationsProps> = ({ applicationId, total }) => {
  const brandToken = useToken('colors', 'brand.blue')

  return (
    <BaseModal
      modalProps={{
        size: 'xl',
      }}
      body={<CalculationsBody applicationId={applicationId} />}
      controller={(onOpen) =>
        typeof total === 'number' ? (
          <Button
            variant={'ghost'}
            p={0}
            px={2}
            m={0}
            ml={-2}
            minH={0}
            height={'30px'}
            color={'brand.blue'}
            _hover={{
              bg: 'transparent',
            }}
            _focus={{
              bg: 'transparent',
            }}
            position={'relative'}
            textDecor={'underline'}
            onClick={(x) => {
              x.preventDefault()
              x.bubbles = false
              x.stopPropagation()
              onOpen()
            }}
          >
            <Text fontWeight={'500'} color={'brand.blue'} mr={2}>
              {ToNoK(total)}
            </Text>
            <ArrowSquareOut size={16} color={brandToken} />
          </Button>
        ) : (
          <Text fontWeight={'normal'}>-</Text>
        )
      }
      title={
        <HStack
          flexDir={{
            base: 'column',
            md: 'row',
          }}
          w={'full'}
          justifyContent={'space-between'}
          alignItems={'flex-start'}
          pr={6}
        >
          <Text fontWeight={'500'}>{`Beregnet stønad`}</Text>
        </HStack>
      }
    ></BaseModal>
  )
}

type CalculationsBodyProps = {
  applicationId: string
}
const CalculationsBody: FC<CalculationsBodyProps> = ({ applicationId }) => {
  const { data: calculations, isLoading } = useGetApplicationCalculations(applicationId)

  return (
    <Grid
      overflowX={'auto'}
      pb={2}
      gridTemplateColumns={{
        base: '1fr',
      }}
    >
      <Table calculations={calculations} isLoading={isLoading} />
    </Grid>
  )
}

type TableProps = {
  calculations?: ApplicationCalculationsModel
  isLoading?: boolean
}
const Table: FC<TableProps> = ({ calculations, isLoading }) => {
  const { t } = useTranslation()

  if (isLoading) {
    return (
      <Center>
        <Spinner />
      </Center>
    )
  }

  if (!calculations) {
    return <MissingCalculations />
  }

  return (
    <Grid w={'full'} gap={3} gridTemplateColumns={`125px 53px 78px 110px auto`}>
      <Text fontWeight={'medium'}>{t('calculations.date')}</Text>
      <Text textAlign={'right'} fontWeight={'medium'}>
        {t('calculations.allowanceDays')}
      </Text>
      <Text textAlign={'right'} fontWeight={'medium'}>
        {t('calculations.dailyAllowance')}
      </Text>
      <Text textAlign={'right'} fontWeight={'medium'}>
        {t('calculations.amount')}
      </Text>
      <Text textAlign={'center'} fontWeight={'medium'}>
        {t('calculations.isSentForPayout')}
      </Text>
      {(!calculations?.payouts?.length && <MissingCalculations />) ||
        calculations?.payouts
          ?.sort((a, b) => {
            const right = +`${b.year}${b.month + 10}`
            const left = +`${a.year}${a.month + 10}`
            return left - right
          })
          .map((calc) => (
            <Fragment key={calc.year + calc.month}>
              <Text>{monthYearOnly.format(new Date(`${calc.year}-${calc.month.toString().padStart(2, '0')}-01`))}</Text>
              <Text textAlign={'right'}>{calc.allowanceDays}</Text>
              <Text textAlign={'right'}>{ToNoK(calc.dailyAllowance)}</Text>
              <Text textAlign={'right'}>{ToNoK(calc.total)}</Text>
              <Text textAlign={'center'}>
                {calc.isSentForPayout || Math.random() > 0.5 ? <Check width={'100%'} /> : <Text>-</Text>}
              </Text>
            </Fragment>
          ))}
      <Divider gridColumn={'span 5'} />
      <Text fontWeight={'medium'}>{t('calculations.total')}</Text>
      <Text fontWeight={'medium'}></Text>
      <Text fontWeight={'medium'}></Text>
      <Text textAlign={'right'} fontWeight={'medium'}>
        {ToNoK(calculations.total ?? 0)}
      </Text>
      <Text fontWeight={'medium'}></Text>
    </Grid>
  )
}

const MissingCalculations: FC = () => {
  const { t } = useTranslation()

  return (
    <Center bg={'gray.100'} p={6} gridColumn={'span 5'}>
      <Text fontStyle={'italic'} opacity={'0.6'}>
        {t('calculations.noCalculationsAvailable')}
      </Text>
    </Center>
  )
}
