import { useQuery } from '@tanstack/react-query'
import { getApplicant, getApplicationsByApplicantId } from './applicant.api'

export const useGetApplicant = (id: string) => {
  return useQuery({
    queryKey: APPLICANT_KEYS.APPLICANT(id),
    queryFn: () => getApplicant(id),
    enabled: !!id,
  })
}

export const useGetApplicationsByApplicantId = (applicantId: string) => {
  return useQuery({
    queryKey: APPLICANT_KEYS.APPLICANT_APPLICATIONS(applicantId),
    queryFn: () => getApplicationsByApplicantId(applicantId),
    enabled: !!applicantId,
    refetchOnWindowFocus: true,
    refetchOnMount: true,
    retry: 2,
  })
}

export const APPLICANT_KEYS = {
  APPLICANT: (id: string) => ['applicant', id],
  APPLICANT_APPLICATIONS: (id?: string) => (id ? ['applicant', 'applications', id] : ['applicant', 'applications']),
}
