import { Box, Button, Heading, Link, Text } from '@chakra-ui/react'
import { ArrowRight } from '@phosphor-icons/react'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { Widget } from '../../components/Widget'
import { env } from '../../config/env'
import { ApplicantModel } from '@/lib/api/applicant.model'

type IntroBoxProps = {
  applicant?: ApplicantModel
}

export const IntroBox: FC<IntroBoxProps> = ({ applicant }) => {
  const { t } = useTranslation()

  return (
    <Widget
      width={'full'}
      display={'flex'}
      flexDir={'column'}
      justifyContent={'space-between'}
      backgroundImage={{
        base: '',
        md: '/lady.png',
      }}
      backgroundRepeat={'no-repeat'}
      backgroundPosition={'right center'}
      backgroundSize={'contain'}
    >
      <Box width={'full'}>
        <Heading
          fontSize={{
            base: '3xl',
            md: '4xl',
            lg: '5xl',
          }}
          variant={'widget'}
        >
          {t('applicationInformationPage.title')}
        </Heading>
        <Text
          maxW={'80ch'}
          variant={'widget'}
          fontSize={{
            base: 'md',
          }}
        >
          {t('applicationInformationPage.description')}
        </Text>
        {!applicant && <Text variant={'widget'}>{t('applicationInformationPage.button.description')}</Text>}
        <Box
          display={'flex'}
          gap={{
            base: 4,
            md: 2,
          }}
          flexDir={{
            base: 'column',
            md: 'row',
          }}
        >
          {!applicant && (
            <Button
              rightIcon={<ArrowRight />}
              variant={'solid'}
              backgroundColor={'#39134C'}
              _hover={{ bg: '#470D70', textDecoration: 'none' }}
              textColor={'#FFFFFF'}
              as={Link}
              textDecoration={'none'}
              href={env.VITE_BANK_ID_LOGIN_URL}
              width={{
                base: 'full',
                md: 'auto',
              }}
            >
              {t('applicationInformationPage.button.text')}
            </Button>
          )}
          {/* Commented out before Go-live, change is requested. */}
          {/* <Button */}
          {/*   width={{ */}
          {/*     base: 'full', */}
          {/*     md: 'auto', */}
          {/*   }} */}
          {/*   colorScheme={'facebook'} */}
          {/*   as={RouterLink} */}
          {/*   to={applicationEligibilityRoute.to} */}
          {/* > */}
          {/*   {t('applicationInformationPage.checkEligibility')} */}
          {/* </Button> */}
        </Box>
      </Box>
      <Link
        mt={6}
        variant={'widget'}
        rel={'noopener noreferrer'}
        target={'_blank'}
        href="https://www.tannlegeforeningen.no/jus-og-arbeidsliv/ved-sykdom-og-svangerskap-/sykehjelpsordningen.html"
        fontSize={{
          base: 'md',
        }}
      >
        {t('applicationInformationPage.link.text')}
      </Link>
    </Widget>
  )
}
