import { selectAnatomy } from '@chakra-ui/anatomy'
import { createMultiStyleConfigHelpers } from '@chakra-ui/react'

const helper = createMultiStyleConfigHelpers(selectAnatomy.keys)

const baseStyle = helper.definePartsStyle({
  field: {
    backgroundColor: 'white',
    borderColor: 'brand.blue',
    borderRadius: 'sm',
  },
})

export const selectTheme = helper.defineMultiStyleConfig({ baseStyle })
