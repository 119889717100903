import { useToast, UseToastOptions } from '@chakra-ui/react'

export const useUserMessage = () => {
  const toast = useToast()

  const showToast = (options: UseToastOptions) => {
    toast({
      duration: 10000,
      isClosable: true,
      position: 'top-right',
      ...options,
    })
  }

  const toastSuccess = (title: string, message?: string) => {
    showToast({
      title,
      status: 'info',
      description: message,
    })
  }

  const toastInfo = (title: string, message?: string) => {
    showToast({
      title,
      status: 'warning',
      description: message,
    })
  }

  return {
    toastSuccess,
    toastInfo,
  }
}
