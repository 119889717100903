import { z } from 'zod'
import { optionalStringOrUndefined } from '../schemaHelpers'
import { GenderEnum } from '../models/enums'

export const AddressModel = z.object({
  street: optionalStringOrUndefined,
  location: optionalStringOrUndefined,
  postalCode: optionalStringOrUndefined,
})

export type AddressModel = z.infer<typeof AddressModel>

export type ApplicantModel = z.infer<typeof ApplicantModel>
export const ApplicantModel = z.object({
  id: z.string(),
  gender: z.nativeEnum(GenderEnum).nullish(),
  givenName: optionalStringOrUndefined,
  surname: optionalStringOrUndefined,
  hprNumber: optionalStringOrUndefined,
  email: optionalStringOrUndefined,
  phoneNumber: optionalStringOrUndefined,
  personNumber: optionalStringOrUndefined,
  bankAccountNumber: optionalStringOrUndefined,
  address: AddressModel.nullish(),
})

export type UpdateApplicantModel = z.infer<typeof UpdateApplicantModel>
export const UpdateApplicantModel = ApplicantModel.extend({
  id: z.string(),
})
