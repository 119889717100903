import { z } from 'zod'
import { t } from 'i18next'

export const requiredFile = z.custom<File>(
  (x) => {
    if (!x) return false
    return x instanceof File
  },
  { message: t('validation.file_required')?.toString() ?? 'Dokumentet må legges ved' }
)
