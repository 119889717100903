import { t } from 'i18next'
import { CaseStatusEnum } from '@/lib/models/enums'

export const getStatusText = ({ status }: { status?: CaseStatusEnum | null }) => {
  if (!status) return t('common.caseStatus.draft')
  switch (status) {
    case CaseStatusEnum.Ny:
      return t('common.caseStatus.new')
    case CaseStatusEnum.Underbehandling:
      return t('common.caseStatus.inProgress')
    case CaseStatusEnum.Godkjent:
      return t('common.caseStatus.approved')
    case CaseStatusEnum.Tilutbetaling:
      return t('common.caseStatus.approved')
    case CaseStatusEnum.Gjenpnet:
      return t('common.caseStatus.reOpened')
    case CaseStatusEnum.Avslag:
      return t('common.caseStatus.rejected')
    case CaseStatusEnum.Avsluttet:
      return t('common.caseStatus.closed')
  }
}
